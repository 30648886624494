import React, { Component } from 'react';
import { Divider, Menu, Icon, Segment, Dropdown } from 'semantic-ui-react'
import { Link, NavLink, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { StyleSheet, css } from 'aphrodite';
import { Translate } from "react-redux-i18n";
import MediaQuery from 'react-responsive'
import { user_logout_request } from '../actions/auth-actions';
import { app_sidebar_toggle } from '../actions/app-actions';
import { setLocale } from 'react-redux-i18n';

const pages = [
	{
		name: "dashboard",
		icon: "map",
		url: '/dashboard',
		roles: ["standard", "admin", "executive", "developer"]
	},
	{
		name: "devices",
		icon: "server",
		url: '/devices',
		roles: ["standard", "admin", "executive", "developer"]
	},
	{
		name: "users",
		icon: "users",
		url: '/users',
		roles: ["admin", "developer"]
	},
	{
		name: "gwcode_editor",
		icon: "code",
		url: '/gwcode_editor',
		roles: ["developer"]
	}
]

class SideMenu extends Component {
	constructor(props){
		super(props);
		this.state={
			animating: false
		}
	}

	// componentDidMount(){
	// 	this.props.history.push({
	// 		pathname: '/devices/'
	// 	});
	// }

	componentWillReceiveProps(props){
		if(props.app.sidebar && props.app.sidebar !== this.props.app.sidebar){
			this.setState({animating: true});
		}
	}

	render() {
		let {name, role} = this.props.auth;
		let activeItem = "";
		return (
			<React.Fragment>
				<MediaQuery maxWidth={1224}>
					<Menu fixed="top" inverted borderless>
						<Dropdown item simple icon="dropdown" text="Akaysis">
							<Dropdown.Menu>
							{pages.map((page, index) => {
								return (
									<Dropdown.Item
										key={index}
										as={Link}
										to={page.url}
										name={page.name}
										onClick={() => { }}
									>
										<Icon name={page.icon} />
										<Translate value={`sideMenu.${page.name}`} />
									</Dropdown.Item>
								)
							})}
								<Dropdown.Item onClick={() => this.props.dispatch(setLocale("en"))}>English</Dropdown.Item>
								<Dropdown.Item onClick={() => this.props.dispatch(setLocale("tr"))}>Türkçe</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Menu.Menu position="right">
							<Dropdown item simple icon="dropdown" text={name}>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => this.logoutAction()}><Translate value="application.logoutButton"/></Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Menu.Menu>
					</Menu>
					<div className={css(styles.contentResponsive)}>
						{this.props.children}
					</div>
				</MediaQuery>
				
				
				<MediaQuery minWidth={1224}> 
					<div className={css(styles.sidebar)}>
						<div className={css(styles.sidebarContent)}>							
							<Menu 
								borderless 
								compact 
								fluid 
								vertical 
								inverted
								style={{display: 'flex', flex: 1, background: 'transparent'}}
							>
								<Menu.Item as="div" style={{padding: 0}}>
									<img src={require('./logo.png')} className={css(styles.logo)} />
								</Menu.Item>
								<Divider fitted></Divider>
								{pages.map((page, index) => {
									if(!page.roles.includes(role)) return null;
									
									return (
										<React.Fragment key={index}>
											<Menu.Item
												key={index}
												as={NavLink}
												to={page.url}
												name={page.name}
												active={false}
												onClick={() => { }}
												activeStyle={{background: "#272b31"}}
											>
												<Icon name={page.icon} style={{float: "left", paddingRight: 30, margin: 0}}/>
												<Translate value={`sideMenu.${page.name}`} />
												<Route path={page.url} render={() => (
													<div className={css(styles.sidebarItem)}> </div>
												)}/>			
											</Menu.Item>
											<Divider fitted></Divider>
										</React.Fragment>
									)
								})}								
							</Menu>
							<Divider fitted></Divider>
							<Menu 
								borderless
								compact
								fluid
								vertical
								inverted
								style={{background: 'transparent'}}
								>
									<Menu.Item>	
									<Menu.Header>
										{name}
									</Menu.Header>
									<Menu.Menu>
										<Menu.Item
											onClick={() => {this.props.dispatch(user_logout_request())}}
										>
											<Icon name="log out" />
											<Translate value="application.logoutButton"/>
										</Menu.Item>
									</Menu.Menu>
								</Menu.Item>
								<Divider fitted></Divider>
								</Menu>
						</div>
					</div>
					<div className={css(styles.content)}>							
							<Segment basic>					
								{this.props.children}
							</Segment>
					</div>
				</MediaQuery>
				

			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	app: state.app
})
export default connect(mapStateToProps)(SideMenu)

const styles = StyleSheet.create({
	logo: {
		height: 120,
	},	
	sidebar: {
		position:"fixed",
		display:"flex",
		flexDirection:"column",
		top:0,
		bottom:0,
		left:0,
		width:250,
		background:"#32363c",
		overflowX:"hidden",
		flex:1
	},
	sidebarContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
	},
	content: {
		margin: 0,
		marginLeft: 250,
		width: 'calc(100vw - 250px)',
		height: '100vh'		
	},
	contentResponsive: {
		margin: 0,
		marginTop: 40,
		height: '100vh',
		overflowX: 'hidden',
		overflowY: 'auto',
		maxHeight: '100vh',
	},
	sidebarItem: {
		position: 'absolute',
		right: 0,
		top: 0,
		bottom: 0,
		width: 0, 
		height: 0, 
		"border-top": "20px solid transparent",
		"border-bottom": "20px solid transparent",
		"border-right": "20px solid rgb(247, 247, 247)"
	  }
});