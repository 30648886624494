import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message } from 'semantic-ui-react'
import { I18n } from 'react-redux-i18n';

export default class Prompt extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false
		}
	}

	close(){
		this.setState({open: false});
	}

	render(){
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				size="small"
			>
				<Header icon={this.props.icon || 'archive'} content={this.props.title} />
				<Modal.Content>
					<p dangerouslySetInnerHTML={{__html: this.props.text}}></p>
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("action.no")} 
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => {this.props.action(); this.close();}}
						content={I18n.t("action.yes")} 
						color='green'
						icon="checkmark"
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}