import React, { Component } from 'react';
import { device_get_details, device_refresh_details } from '../actions/device-details-actions';
import { connect } from 'react-redux';
import { Table, Loader, Icon, Menu, Segment, Header, Grid, Button, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
// import { gwcommands_list_request } from '../actions/gwcommands-actions';
import { users_list_request } from '../actions/users-actions';
import { device_auth_user_action } from '../actions/device-auth-actions';

class DeviceDetails_AccessManagement extends Component{


	componentDidMount(){
		this.props.dispatch(users_list_request());
	}

	update_auth(id, op){
		this.props.dispatch(device_auth_user_action(this.props.deviceId, id, op))
		.then((res, rej) => {
			if(res){
				this.props.dispatch(device_refresh_details(this.props.deviceId));
			}
		})
	}


	render(){
		let {data, loading} = this.props.users;
		let {authorized_users} = this.props.deviceDetails.data;
		return (
			<Segment basic attached="bottom" style={{padding: 0, flex: 1}}>
					<Table fixed celled style={{margin: 0, border: 'none'}}>
						<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={7} textAlign="center">User Name</Table.HeaderCell>
							<Table.HeaderCell width={3} textAlign="center">Role</Table.HeaderCell>
							<Table.HeaderCell width={6} textAlign="center">Actions</Table.HeaderCell>
						</Table.Row>
						</Table.Header>
					</Table>
						{data && authorized_users && data.length > 0 ? (
							<div style={{overflowY: 'auto', overflowX: 'none', maxHeight:'calc(100vh - 220px)',overflowY:'auto'}}>
								<Table fixed celled style={{margin: 0, border: 'none'}}>
									<Table.Body>
										{data.map(x=>{
											return (<Table.Row key={x._id}>
												<Table.Cell width={7} textAlign="center">{`${x.name} (${x.email})`}</Table.Cell>
												<Table.Cell width={3} textAlign="center">{x.role}</Table.Cell>
												<Table.Cell width={6} textAlign="center">
												{ 
													(
														x.role === "developer" || x.role === "admin") ? (
														"Admins always granted"
													) : (
														authorized_users.includes(x._id) ? (
															<Button size="tiny" color="red" onClick={() => {this.update_auth(x._id, false)}}>Remove Access</Button>
														) : (
															<Button size="tiny" color="green" onClick={() => {this.update_auth(x._id, true)}}>Grant Access</Button>
														)
													)
												}</Table.Cell>
											</Table.Row>)
										})}
									</Table.Body>
								</Table>
							</div>
						) : null }	
					{loading ? (
						<Message attached='bottom'>
						<Icon name='circle notched' loading={true} />
							Loading Data
						</Message>
					) : null}
				
			</Segment>
		);
	}
}


const mapStateToProps = state => ({
	deviceDetails: state.deviceDetails,
	users: state.users
})
export default connect(mapStateToProps)(DeviceDetails_AccessManagement)