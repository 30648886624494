import {
	CITIES_LIST_ACTION,
	CITIES_LIST_SUCCESS,
	CITIES_LIST_FAILURE
} from './actionList';
import axios from 'axios';

import {store} from '../store';



export const cities_list_set_pending = () => ({
	type: CITIES_LIST_ACTION
});

export const cities_list_set_successful = (res) => ({
	type: CITIES_LIST_SUCCESS,
	response: res
});

export const cities_list_set_failed = () => ({
	type: CITIES_LIST_FAILURE
});

export const cities_list_request = () => dispatch => {
	dispatch(cities_list_set_pending());
	axios.get(process.env.REACT_APP_SERVER_URL + '/cities/list',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(cities_list_set_successful(res.data));
		} else {
			dispatch(cities_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(cities_list_set_failed());
	});
}
