export const tr = {
	action: {
		"yes": "Evet",
		"no": "Hayır",
		"ok": "Tamam"
	},
	sys: {
		"system-generated": "Otomatik Aksiyon",
		"timed-action": "Zamanlanmış Görev",
		"register-action": "Otomatik Güncelleme",
		"unknown": "Bilinmiyor"
	},
	application: {
		title: 'Akaysis Akıllı Aydınlatma Portalı',
		langButton: "Uygulama Dili",
		logoutButton: "Çıkış Yap",
		loadingData: "Bilgiler Yükleniyor",
		reauthing: "Kimliğiniz Doğrulanıyor"
	},
	sideMenu: {
		"devices": "Cihazlar",
		"users": "Kullanıcılar",
		"gwcode_editor": "Cihaz Kod Editörü",
		"dashboard": "Harita"
	},
	dashboard: {
		header: "Harita"
	},
	devices: {
		header: "Cihazlar",
		refreshButton: "Yenile",
		macAddress: "MAC Adresi",
		deviceName: "Cihaz Adı",
		deviceStatus: "Durumu",
		detailsButton: "Detaylar",
		deviceCity : "Bulunduğu Şehir",
		noDeviceAllowed: "Yetkili olduğunuz herhangi bir cihaz bulunamadı. Lütfen sistem yöneticiniz ile temasa geçiniz."
	},
	users: {
		header: "Kullanıcılar",
		refreshButton: "Yenile",
		createUser: "Yeni Kullanıcı",
		editUser: "Bilgileri Düzenle",
		deleteUser: "Kullanıcıyı Sil",
		name: "İsim",
		email: "E-Posta",
		role: "Yetki",
		remove_title: "Kullanıcı Sil",
		remove_text: "<strong>%{user}</strong> isimli kullanıcı silinecektir. Bu işlem geri döndürülemez.<br/>Devam etmek istediğinize emin misiniz?"
	},
	users_modify: {
		header: "Kullanıcıyı Düzenle",
		name: "İsim",
		email: "E-Posta",
		password: "Parola",
		password2: "Parola(Tekrar)",
		role: "Görev",
		cancel: "İptal",
		update: "Güncelle"
	},
	users_create: {
		header: "Yeni Kullanıcı",
		name: "İsim",
		email: "E-Posta",
		password: "Parola",
		password2: "Parola(Tekrar)",
		role: "Görev",
		cancel: "İptal",
		create: "Kaydet"
	},
	deviceDetails: {
		header: "Cihaz: ",
		editInformations: "Bilgileri Düzenle",
		refresh: "Yenile",
		menu_overview: "Genel Bakış",
		mesh_items: "Mesh Yönetimi",
		menu_programs: "Haftalık Program",
		menu_history: "Komut Geçmişi",
		menu_access: "Erişim Yönetimi",
		menu_graphs: "Veri Analizleri",
		deleteDevice: "Cihazı Sil",
		deletePromptTitle: "Cihazı Sil",
		deletePromptMessage: "Cihaz kalıcı olarak silinecektir. Devam edilsin mi?"
	},
	deviceDetails_Overview: {
		macAddress: "MAC Adresi",
		deviceName: "Cihaz Adı",
		deviceStatus: "Bağlantı Durumu",
		websocketPort: "Websoket Portu",
		rootMeshIP: "Mesh Kök IP Adresi",
		rootMeshIPUnknown: "Bağlı Mesh Cihazı Bulunmuyor",
		status_Active: "AKTİF",
		status_Inactive: "BAĞLANTI YOK",
		wsPortUnknown: "Port Bilinmiyor",
		signalStrength: "Sinyal Gücü",
		firmwareVersion: "Yazılım Sürümü",
		layer: "Katman",
		deviceMode: "Mod",
		deviceBrightness: "Parlaklık",
		allNodesHeader: "Tüm Mesh Cihazları",
		meshNodesTitle: "Mesh Cihazları",
		commandModeButton: "Komut Modu",
		mode_on: "Açık",
		mode_off: "Kapalı",
		mode_blink: "Flaş",
		brightness_current: "Mevcut:",
		device_inactive_title: "Cihaz Bağlı Değil",
		device_inactive_msg: "Komut modu sadece cihaz bağlı iken aktif olabilmektedir.",
		sunset_sunrise_times: "Gün Doğumu/Batımı Saatleri",
		deviceCity: "Cihazın Bulunduğu Şehir",
		cmd_status_pending: "Uygulanıyor",
		cmd_status_failed: "Başarısız",
		cmd_status_completed: "Tamamlandı",
		datetime: "Tarih/Saat",
		command: "Komut Adı",
		user: "Kullanıcı",
		status: "Durum",
		connection: "Bağlantı",
		eMeterReadTimeStamp: "Sayaç Okuma Zamanı",
		eMeterConsumption: "Sayaç Son Kümülatif Değeri",
		eMeterVoltage: "Sayaç Voltaj Ölçümleri",
		eMeterCurrent: "Sayaç Akım Ölçümleri"		
	},
	deviceDetails_Programs: {
		timeType: "Zamanlama Türü",
		header: "Haftalık Programlar",
		time: "Saat",
		offset: "Offset (Dakika)",
		brightness: "Parlaklık",
		value: "Değer",
		brightness2: "Parlaklık (%)",
		monday: "Pazartesi",
		tuesday: "Salı",
		wednesday: "Çarşamba",
		thursday: "Perşembe",
		friday: "Cuma",
		saturday: "Cumartesi",
		sunday: "Pazar",
		editButton: "Düzenle",
		removeButton: "Sil",
		newItemButton: "Yeni Program Ekle",
		Sunrise: "Gün Doğumu (%{time})",
		Sunset: "Gün Batımı (%{time})",
		UserDefined : "Kullanıcı Tanımlı",
		deletePromptTitle: "Program Silme Onayı",
		deletePromptMessage: "Bu programı silmeyi onayladığınız takdirde işlemi geri alamayacaksınız<br/>Onaylıyor musunuz?",
		createProgramTitle: "Yeni Program Ekle",
		editProgramTitle: "Program Düzenle",
		appliedDays: "Programın Geçerli Olacağı Günler",
		createButton: "Programı Kaydet",
		cancelButton: "İptal",
		valueType: "Program Türü",
		value_brightness: "Parlaklık",
		value_hardswitch: "Kontaktör",
		hardswitch_on: "Kontaktör Etkin (Çekili)",
		hardswitch_off: "Kontaktör Devre Dışı"
	}
};
