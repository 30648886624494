// @flow

export const USER_LOGIN_ACTION   = "user:login:action";
export const USER_LOGIN_SUCCESS  = "user:login:success";
export const USER_LOGIN_FAILURE  = "user:login:failure";

export const USER_LOGOUT_ACTION  = "user:logout:action";

export const USER_REAUTH_ACTION  = "user:reauth:action";
export const USER_REAUTH_SUCCESS = "user:reauth:success";
export const USER_REAUTH_FAILURE = "user:reauth:failure";

export const APP_SIDEBAR_TOGGLE  = "app:sidebar:toggle";

export const USERS_LIST_ACTION = "users:list:action";
export const USERS_LIST_SUCCESS = "users:list:success";
export const USERS_LIST_FAILURE = "users:list:failure";

export const USER_CREATE_ACTION =  "user:create:action";
export const USER_CREATE_SUCCESS = "user:create:success";
export const USER_CREATE_FAILURE = "user:create:failure";


export const USER_DELETE_ACTION =  "user:delete:action";
export const USER_DELETE_SUCCESS = "user:delete:success";
export const USER_DELETE_FAILURE = "user:delete:failure";

export const USER_UPDATE_ACTION =  "user:update:action";
export const USER_UPDATE_SUCCESS = "user:update:success";
export const USER_UPDATE_FAILURE = "user:update:failure";


export const DEVICE_LIST_ACTION  = "device:list:action";
export const DEVICE_REFRESH_ACTION = "device:refresh:action";
export const DEVICE_LIST_SUCCESS = "device:list:success";
export const DEVICE_LIST_FAILURE = "device:list:failure";


export const DEVICE_DETAILS_ACTION  = "device:details:action";
export const DEVICE_DETAILS_SUCCESS = "device:details:success";
export const DEVICE_DETAILS_FAILURE = "device:details:failure";

export const DEVICE_DELETE_ACTION  = "device:delete:action";
export const DEVICE_DELETE_SUCCESS = "device:delete:success";
export const DEVICE_DELETE_FAILURE = "device:delete:failure";


export const MESH_ITEM_DELETE_ACTION  = "mesh_item:delete:action";
export const MESH_ITEM_DELETE_SUCCESS = "mesh_item:delete:success";
export const MESH_ITEM_DELETE_FAILURE = "mesh_item:delete:failure";

export const MESH_ITEM_UPDATE_ACTION  = "mesh_item:update:action";
export const MESH_ITEM_UPDATE_SUCCESS = "mesh_item:update:success";
export const MESH_ITEM_UPDATE_FAILURE = "mesh_item:update:failure";


export const DEVICE_AUTH_USER_ADD_ACTION = "device:authorized_users:add:action";
export const DEVICE_AUTH_USER_REMOVE_ACTION = "device:authorized_users:remove:action";
export const DEVICE_AUTH_USER_CHANGE_COMPLETED = "device:authorized_users:change:completed";
export const DEVICE_AUTH_USER_CHANGE_FAILED = "device:authorized_users:change:failed";

export const DEVICE_INFO_SAVE_ACTION  = "device:info_save:action";
export const DEVICE_INFO_SAVE_SUCCESS = "device:info_save:success";
export const DEVICE_INFO_SAVE_FAILURE = "device:info_save:failure";

export const GWCODES_LIST_ACTION = "gwcodes:list:action";
export const GWCODES_LIST_SUCCESS = "gwcodes:list:success";
export const GWCODES_LIST_FAILURE = "gwcodes:list:failure";

export const GWCODE_SINGLE_GET_ACTION = "gwcode:get:action";
export const GWCODE_SINGLE_GET_SUCCESS = "gwcode:get:success";
export const GWCODE_SINGLE_GET_FAILURE = "gwcode:get:failure";

export const GWCODE_SINGLE_SAVE_ACTION = "gwcode:save:action";
export const GWCODE_SINGLE_SAVE_SUCCESS = "gwcode:save:success";
export const GWCODE_SINGLE_SAVE_FAILURE = "gwcode:save:failure";

export const GWCODE_SINGLE_PUSH_ACTION = "gwcode:push:action";
export const GWCODE_SINGLE_PUSH_SUCCESS = "gwcode:push:success";
export const GWCODE_SINGLE_PUSH_FAILURE = "gwcode:push:failure";



export const GWCOMMANDS_LIST_ACTION = "gwcommands:list:action";
export const GWCOMMANDS_LIST_SUCCESS = "gwcommands:list:success";
export const GWCOMMANDS_LIST_FAILURE = "gwcommands:list:failure";

export const GWCOMMANDS_ADD_ACTION = "gwcommands:add:action";
export const GWCOMMANDS_ADD_SUCCESS = "gwcommands:add:success";
export const GWCOMMANDS_ADD_FAILURE = "gwcommands:add:failure";



export const GWPROGRAMS_LIST_ACTION  = "gwprograms:list:action";
export const GWPROGRAMS_LIST_SUCCESS = "gwprograms:list:success";
export const GWPROGRAMS_LIST_FAILURE = "gwprograms:list:failure";

export const GWPROGRAMS_LIST_ADD_ACTION = "gwprograms:add:action";
export const GWPROGRAMS_LIST_ADD_SUCCESS = "gwprograms:add:success";
export const GWPROGRAMS_LIST_ADD_FAILURE = "gwprograms:add:failure";

export const GWPROGRAMS_LIST_CHANGE_FIELD = "gwprograms:change:field";


export const CITIES_LIST_ACTION = "cities:list:action";
export const CITIES_LIST_SUCCESS = "cities:list:success";
export const CITIES_LIST_FAILURE = "cities:list:failure";


export const GRAPH_FETCH_ACTION =  "graph:fetch:action";
export const GRAPH_FETCH_SUCCESS = "graph:fetch:success";
export const GRAPH_FETCH_FAILURE = "graph:fetch:failure";
