import {
	USER_LOGIN_ACTION,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAILURE, 
	USER_LOGOUT_ACTION, 
	USER_REAUTH_ACTION,
	USER_REAUTH_SUCCESS,
	USER_REAUTH_FAILURE

} from './actionList';
import axios from 'axios';

import { error, success } from 'react-notification-system-redux';





export const user_login_set_pending = (email) => ({
	type: USER_LOGIN_ACTION,
	email: email,
})

export const user_reauth_set_pending = (token) => ({
	type: USER_REAUTH_ACTION,
	token: token
})

export const user_login_set_successful = (email, res) => ({
	type: USER_LOGIN_SUCCESS,
	email: email,
	response: res
})

export const user_login_set_failed = (email, res = {}) => ({
	type: USER_LOGIN_FAILURE,
	email: email,
	response: res
})

export const user_logout_request = () => ({
	type: USER_LOGOUT_ACTION
})

export const user_reauth_set_successful = (token, res) => ({
	type: USER_REAUTH_SUCCESS,
	token: token,
	response: res
})

export const user_reauth_set_failure = () => ({
	type: USER_REAUTH_FAILURE
})

export const user_login_request = (email, password) => dispatch => {
	dispatch(user_login_set_pending(email))
	axios.post(process.env.REACT_APP_SERVER_URL + '/auth/login', {
		email: email,
		password: password
	})
		.then(res => {
			if (res.status === 200) {
				if(res.data.status && res.data.status === "successful"){
					dispatch(user_login_set_successful(email, res));
					dispatch(success({title: "Login Successful", message: "Welcome " + res.data.name}))
				} else if(res.data.status && res.data.status === "failed"){
					dispatch(user_login_set_failed(email, res));
				}

			} 
		})
		.catch((err) => {
			// dispatch(error({title: "Error Occured", message:err.message}))
			dispatch(user_login_set_failed(email, {data: {explanation: err.message}}));
			console.log(JSON.stringify(err));
		});
}

export const user_reauth_request = (token) => dispatch => {
	dispatch(user_reauth_set_pending(token));
	axios.get(
		process.env.REACT_APP_SERVER_URL + '/auth/verifytoken/', 
		{
			headers: {Authorization: 'Bearer ' + token
		}
	})
		.then(res => {
			if (res.status === 200) {
				dispatch(user_reauth_set_successful(token, res.data));
			}
			else if (res.status === 401) {
				dispatch(user_reauth_set_failure(res));
			}
		})
		.catch((err) => {
			dispatch(user_reauth_set_failure());
			dispatch(error({title: "Re-Auth Failed", message: "The authorization token your browser has is not valid anymore. This problem usually happens when user logs in from another device. Just try to log-in again.", autoDismiss: 10}));
		});
}
