export const en = {
	application: {
		title: 'Akaysis Smart Lightning Portal',
		langButton: "Language",
		logoutButton: "Log Out",
		loadingData: "Loading Data",
		reauthing: "Reauthing"
	},
	sideMenu: {
		"devices": "Devices",
		"users": "Users",
		"gwcode_editor": "Gateway Code Editor"
	},
	devices: {
		header: "Devices",
		refreshButton: "Refresh",
		macAddress: "MAC Address",
		deviceName: "Device Name",
		deviceStatus: "Device Status",
		detailsButton: "Details"
	},
	deviceDetails: {
		header: "Device: ",
		editInformations: "Edit Informations",
		refresh: "Refresh",
		menu_overview: "Overview",
		menu_programs: "Weekly Programs",
		menu_history: "Command History",
		menu_access: "Access Management",
		deleteDevice: "Delete Device"
	},
	deviceDetails_Overview: {
		macAddress: "MAC Address",
		deviceName: "Device Name",
		deviceStatus: "Status",
		websocketPort: "Websocket Port",
		status_Active: "Active",
		status_Inactive: "Inactive",
		wsPortUnknown: "Port Unknown",
		signalStrength: "Signal Strength",
		firmwareVersion: "Firmware Version",
		rootMeshIP: "IP Address of Root Mesh item",
		rootMeshIPUnknown: "No IP found",
		layer: "Layer",
		deviceMode: "Mode",
		deviceBrightness: "Brightness",
		allNodesHeader: "All Nodes",
		meshNodesTitle: "Mesh Nodes",
		commandModeButton: "Command Mode",
		mode_on: "On",
		mode_off: "Off",
		mode_blink: "Blink",
		brightness_current: "Current:",
		device_inactive_title: "Device is Inactive",
		device_inactive_msg: "You can only open Command mode while device is Active",
		cmd_status_pending: "Pending",
		cmd_status_failed: "Failed",
		cmd_status_completed: "Completed",
		datetime: "Date/Time",
		command: "Command",
		user: "User",
		status: "Status",
		connection: "Connection"
	},
	deviceDetails_Programs: {
		header: "Programs",
		time: "Saat",
		brightness: "Parlaklık",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
		sunday: "Sunday",
		editButton: "Edit",
		removeButton: "Remove",
		newItemButton: "New Program",
		Sunrise: "Sunrise (%{time})",
		Sunset: "Sunset (%{time})",
		deletePromptTitle: "Deleting Program",
		deletePromptMessage: "The program will be permanently deleted...<br/>Are you sure to continue?",
	}
};