import React, { Component } from 'react';
import { device_get_details, device_refresh_details } from '../actions/device-details-actions';
import { connect } from 'react-redux';
import { Table, Message, Header, Icon, Menu, Segment, Dropdown, Grid, Input, Select, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { gwprograms_list_request, gwprograms_delete_request } from '../actions/gwprograms-actions';
import DeviceDetails_Programs_Create from './DeviceDetails.Programs.Create';
import DeviceDetails_Programs_Edit from './DeviceDetails.Programs.Edit';
import Prompt from './Prompt';
import { Translate, I18n } from "react-redux-i18n";
import { Mobile, Default } from '../components/responsive';

class DeviceDetails_Programs extends Component{

	componentDidMount(){
		this.props.dispatch(gwprograms_list_request(this.props.deviceId));
	}

	render(){
		let {data, loading} = this.props.gwprograms;
		return (
			<React.Fragment>
				<Mobile>
					<Segment attached secondary>
						<DeviceDetails_Programs_Create device_id={this.props.deviceId} city_details={this.props.deviceDetails.data.device_city_details ? this.props.deviceDetails.data.device_city_details : null} dispatch={this.props.dispatch} loading={loading}>
							<Button
								fluid
								name='add'
								size="tiny"
								primary
							>
								<Icon name="add" />
								<Translate value="deviceDetails_Programs.newItemButton" />
							</Button>
						</DeviceDetails_Programs_Create>
					</Segment>
				</Mobile>
				
				<Segment attached style={{padding: 0, flex: 1, margin: 0, overflow: 'auto', scrollX: "auto"}}>
					<Table unstackable fixed celled style={{margin: 0, border: 'none', minWidth: 500}}>
						<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={2} textAlign="center"><Translate value="deviceDetails_Programs.time" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.value" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.monday" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.tuesday" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.wednesday" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.thursday" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.friday" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.saturday" /></Table.HeaderCell>
							<Table.HeaderCell width={1} textAlign="center"><Translate value="deviceDetails_Programs.sunday" /></Table.HeaderCell>
							<Default>
								<Table.HeaderCell width={3} textAlign="center">
									<DeviceDetails_Programs_Create device_id={this.props.deviceId} city_details={this.props.deviceDetails.data.device_city_details ? this.props.deviceDetails.data.device_city_details : null} dispatch={this.props.dispatch} loading={loading}>
										<Button
											name='add'
											size="tiny"
											primary
										>
											<Icon name="add" />
											<Translate value="deviceDetails_Programs.newItemButton" />
										</Button>
									</DeviceDetails_Programs_Create>
								</Table.HeaderCell>
							</Default>
						</Table.Row>
						</Table.Header>
					</Table>
					<div style={{maxHeight:'calc(100vh - 220px)'}}>
						<Table unstackable fixed celled style={{margin: 0, border: 'none', minWidth: 500}}>
						{data && data.length > 0 ? (
							<Table.Body>
								{data.map(x=>{
									return (
										<React.Fragment key={x._id}>
											<Table.Row key={x._id}>
												<Table.Cell textAlign="center" width={2}>{
													x.time_type === "User-Defined" ? 
													x.user_defined_time : (
														<Translate value={"deviceDetails_Programs." + x.time_type} time={x.user_defined_time}/>)
													}
												</Table.Cell>
												<Table.Cell textAlign="center" width={1}>
													{ x.value_type == null || x.value_type === "brightness" ? ( 
														x.brightness + " %"
													) : null}

													{ x.value_type === "hardswitch" ? ( 
														x.hardswitch ? "ON" : "OFF"
													) : null}
												</Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.monday 	? "check" : "minus"} /></Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.tuesday 	? "check" : "minus"} /></Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.wednesday	? "check" : "minus"} /></Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.thursday 	? "check" : "minus"} /></Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.friday 	? "check" : "minus"} /></Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.saturday 	? "check" : "minus"} /></Table.Cell>
												<Table.Cell textAlign="center" width={1}><Icon name={x.sunday 	? "check" : "minus"} /></Table.Cell>
												<Default>
													<Table.Cell textAlign="center" width={3}>
														<DeviceDetails_Programs_Edit data={x} key={x._id} city_details={this.props.deviceDetails.data.device_city_details ? this.props.deviceDetails.data.device_city_details : null} dispatch={this.props.dispatch} device_id={this.props.deviceId} loading={loading}>
															<Button primary size="tiny">
																<Translate value="deviceDetails_Programs.editButton" />
															</Button>
														</DeviceDetails_Programs_Edit>
														<Prompt 
															action={() => this.props.dispatch(gwprograms_delete_request(x._id, this.props.deviceId))} 
															title={I18n.t("deviceDetails_Programs.deletePromptTitle")}
															text={I18n.t("deviceDetails_Programs.deletePromptMessage")}>
															<Button color="red" size="tiny"><Translate value="deviceDetails_Programs.removeButton" /></Button>
														</Prompt>
													</Table.Cell>
												</Default>
											</Table.Row>
											<Mobile>
												<Table.Row>
													<Table.Cell colSpan={9} textAlign="right">
														<DeviceDetails_Programs_Edit data={x} key={x._id} city_details={this.props.deviceDetails.data.device_city_details ? this.props.deviceDetails.data.device_city_details : null} dispatch={this.props.dispatch} device_id={this.props.deviceId} loading={loading}>
															<Button primary size="tiny">
																<Translate value="deviceDetails_Programs.editButton" />
															</Button>
														</DeviceDetails_Programs_Edit>
														<Prompt 
															action={() => this.props.dispatch(gwprograms_delete_request(x._id, this.props.deviceId))} 
															title={I18n.t("deviceDetails_Programs.deletePromptTitle")}
															text={I18n.t("deviceDetails_Programs.deletePromptMessage")}>
															<Button color="red" size="tiny"><Translate value="deviceDetails_Programs.removeButton" /></Button>
														</Prompt>
													</Table.Cell>
												</Table.Row>
											</Mobile>
										</React.Fragment>
									)
								})}
							</Table.Body>
						): null}
						</Table>
					</div>
				</Segment>
				{loading ? (
					<Message as={Segment} attached='bottom'>
					<Icon name='circle notched' loading={true} />
						<Translate value="application.loadingData" />
					</Message>
					) : null}
			</React.Fragment>

		);
	}
}


const mapStateToProps = state => ({
	deviceDetails: state.deviceDetails,
	gwprograms: state.gwprograms
})
export default connect(mapStateToProps)(DeviceDetails_Programs)