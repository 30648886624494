import {
	GWCODES_LIST_ACTION,
	GWCODES_LIST_SUCCESS,
	GWCODES_LIST_FAILURE
} from '../actions/actionList'

const gwcodes = (state = {data: [], loading: false}, action) => {
	switch (action.type) {
		case GWCODES_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case GWCODES_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case GWCODES_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default gwcodes;
