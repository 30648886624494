	import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
    Table,
    Loader,
    Icon,
    Menu,
    Segment,
    Message,
    Grid,
    Progress,
    Input,
	Header,
	Button,
	Label
} from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { gwcommands_add_request } from '../actions/gwcommands-actions';
import { error } from 'react-notification-system-redux';
import { Translate, I18n } from "react-redux-i18n";

class DeviceDetails_Overview extends Component{
	constructor(params){
		super(params);
		this.state = {
			editMode: false
		}
	}
	
	render(){
		let {data} = this.props.deviceDetails;		

		return (
			<React.Fragment>
				<Loader active={this.props.deviceDetails.loading} inline='centered' indeterminate/>
				<Segment basic style={{padding: 0, margin: 0}}>
						<Segment style={{padding: 0, flex: 1}}>
						<Table unstackable fixed definition style={{margin: 0, border: 'none'}}>
							<Table.Body>
								<Table.Row>
									<Table.Cell width={8}><Translate value="deviceDetails_Overview.deviceStatus" /></Table.Cell>
									<Table.Cell><Label color={data.device_status == "Active" ? "green" : "red"}><Translate value={`deviceDetails_Overview.status_${data.device_status}`} /></Label></Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.deviceName" /></Table.Cell>
									<Table.Cell>
										{data.device_name}
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.deviceCity" /></Table.Cell>
									<Table.Cell>
										{data.device_city_details ? data.device_city_details.name :  (<Translate value="deviceDetails_Overview.wsPortUnknown" />)}
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.sunset_sunrise_times" /></Table.Cell>
									<Table.Cell>
										{data.device_city_details ? data.device_city_details.sunriseTime + " - " +  data.device_city_details.sunsetTime:  (<Translate value="deviceDetails_Overview.wsPortUnknown" />)}
									</Table.Cell>
								</Table.Row>
								{data.mac_addr ? (
									<Table.Row>
										<Table.Cell><Translate value="deviceDetails_Overview.macAddress" /></Table.Cell>
										<Table.Cell>{data.mac_addr}</Table.Cell>
									</Table.Row>
								) : null}
								{data.websocketPort ? (
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.websocketPort" /></Table.Cell>
									<Table.Cell>{data.websocketPort || (<Translate value="deviceDetails_Overview.wsPortUnknown" />)}</Table.Cell>
								</Table.Row>
								): null}
								{data.root_mesh_ip ? (
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.rootMeshIP" /></Table.Cell>
									<Table.Cell>{data.root_mesh_ip || (<Translate value="deviceDetails_Overview.rootMeshIPUnknown" />)}</Table.Cell>
								</Table.Row>
								): null}


								{data.eMeterExists ? (
								<React.Fragment>
									<Table.Row>
										<Table.Cell><Translate value="deviceDetails_Overview.eMeterReadTimeStamp" /></Table.Cell>
										<Table.Cell>{data.eMeterReadTimestamp || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell><Translate value="deviceDetails_Overview.eMeterConsumption" /></Table.Cell>
										<Table.Cell>{data.eMeterConsumptionValue || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell><Translate value="deviceDetails_Overview.eMeterVoltage" /></Table.Cell>
										<Table.Cell>{data.eMeterVoltageValue || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
									</Table.Row>
									<Table.Row>
										<Table.Cell><Translate value="deviceDetails_Overview.eMeterCurrent" /></Table.Cell>
										<Table.Cell>{data.eMeterCurrentValue || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
									</Table.Row>
								</React.Fragment>
								): null}


								
								{data.eContactExists ? (
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.eContact" /></Table.Cell>
									<Table.Cell><Label color={data.eContactValue ? "green" : "red"}>{data.eContactValue ? "ON" : "OFF"}</Label></Table.Cell>
								</Table.Row>
								): null}



								{data.tempSensorExists ? (
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.tempSensor" /></Table.Cell>
									<Table.Cell>{data.tempSensorValue || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
								</Table.Row>
								): null}	

								{data.humiditySensorExists ? (
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.humiditySensor" /></Table.Cell>
									<Table.Cell>{data.humiditySensorValue || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
								</Table.Row>
								): null}								


								{data.airQualitySensorExists ? (
								<Table.Row>
									<Table.Cell><Translate value="deviceDetails_Overview.airQualitySensor" /></Table.Cell>
									<Table.Cell>{data.airQualitySensorValue || (<Translate value="deviceDetails_Overview.valueUnknown" />)}</Table.Cell>
								</Table.Row>
								): null}	

							</Table.Body>
						</Table>
						</Segment>
				</Segment>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	deviceDetails: state.deviceDetails
})
export default connect(mapStateToProps)(DeviceDetails_Overview)