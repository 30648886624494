// @flow
import React, { Component } from 'react';
import { gwcode_list_request } from '../actions/gwcodes-actions';
import { gwcode_single_get_request, gwcode_single_save_request, gwcode_single_push_request } from '../actions/gwcode-single-actions';
import { Header, Table, Loader, Button, Icon, Menu, Segment, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import {ControlledEditor} from '@monaco-editor/react';

type Props = {
	dispatch: any,
	history: any,
	match: any,

	
	gwcodes: any,
	gwcode_single: any
};

type State = {
	codeId: string,
	code: string
}

export class GWCodeEditor extends Component<Props, State>{

	constructor(params:any){
		super(params);

		this.state = {
			codeId: "",
			code: ""
		};
	}


	componentWillMount(){
		this.props.dispatch(gwcode_list_request()).then(() => {
			if(!this.state.codeId || this.state.codeId === ""){
				this.props.history.push({
					pathname: '/gwcode_editor/' + this.props.gwcodes.data[0]._id
				});
			} 
		});
	}

	componentWillReceiveProps(props : any){
		if(props.match.params.codeId !== this.state.codeId){
			this.setState({codeId: this.props.match.params.codeId}, () => {
				this.loadData();
			});
		}
	}

	loadData(){
		if(this.state.codeId && this.state.codeId !== ""){
			this.props.dispatch(gwcode_single_get_request(this.state.codeId)).then(() => {
				this.setState({
					code: this.props.gwcode_single.data.code
				});
			});
		}
	}

	saveCode(){
		this.props.dispatch(gwcode_single_save_request(this.props.match.params.codeId, this.state.code));
	}

	pushCode(){
		this.props.dispatch(gwcode_single_push_request(this.props.match.params.codeId));
	}

	render(){
		let { code, codeId } = this.state;
		return (
			<div>
				<Menu borderless attached='top' style={{borderRadius: 0}}>
					<Menu.Item as="h4" header>Gateway Code Editor</Menu.Item>
					<Dropdown item text={"Selected Code: " + (this.props && this.props.gwcode_single && this.props.gwcode_single.data ? this.props.gwcode_single.data.name : "Unknown")}>
						<Dropdown.Menu>
							{this.props.gwcodes.data.map(x=>{
								return (
									<Dropdown.Item
									key={x._id}
									as={Link}
									to={"/gwcode_editor/" + x._id}>
											{x.name}
									</Dropdown.Item>
								)
							})}
						</Dropdown.Menu>
					</Dropdown>
					<Menu.Menu position='right'>
						<Menu.Item
							name='Push to gateways'
							as={Button}
							onClick={() => this.pushCode()}
							>
							<Icon 
								loading={this.props.gwcode_single.pushing} 
								name={this.props.gwcode_single.pushing ? "spinner" : "download"} 
							/>
							Push into Gateways
						</Menu.Item>
						<Menu.Item
							name='Save'
							as={Button}
							onClick={() => this.saveCode()}
							>
							<Icon 
								loading={this.props.gwcode_single.saving} 
								name={this.props.gwcode_single.saving ? "spinner" : "save"} 
							/>
							Save
						</Menu.Item>
					</Menu.Menu>
				</Menu>
				<Segment attached style={{padding: 0}}>
					<Loader active={this.props.gwcode_single.loading} inline='centered' indeterminate />
					{codeId && codeId !== "" ? (
						<ControlledEditor
							height="calc(100vh - 88px)"
							language="javascript"
							value={code}
							onChange={(ev, value) => {this.setState({code: value})}}
						/>
					): null}
				</Segment>
			</div>

			
		);
	}
}

const mapStateToProps = state => ({
	gwcodes: state.gwcodes,
	gwcode_single: state.gwcode_single
})

// const styles = StyleSheet.create({
// 	editor: {
// 		'height': '100%'
// 	}
// });


export default connect(mapStateToProps)(GWCodeEditor)