import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message } from 'semantic-ui-react'
import { users_update_request } from '../actions/users-actions';
import { Translate, I18n } from "react-redux-i18n";

export default class Users_Modify extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			email: this.props.data.email,
			password: "",
			password2: "",
			name: this.props.data.name,
			role: this.props.data.role,
			errorMessage: ""
		}
	}
	
	submitForm(){
		if(this.state.password !== this.state.password2){
			this.setState({errorMessage: 'Passwords does not match'});
		} else {
			this.setState({errorMessage: null}, () => {
				let {open, errorMessage, ...rest} = this.state;
				this.props.dispatch(users_update_request(this.props.userId, rest))
				.then((resolved , rejected) => {
					if(resolved){
						this.close();
					}
				})
				.catch(err => {
					this.setState({errorMessage : err.message});
				})
			});
		}
	}

	close(){
		this.setState({open: false});
	}

	render(){
		let status = false;
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				closeOnDimmerClick={false} 
			>
				<Header icon='archive' content={I18n.t("users_modify.header")} />
				<Modal.Content>
					<Form>
						<Form.Input label={I18n.t("users_modify.name")} name="name" placeholder='Name' type='text' onChange={(e, data) => this.setState({name: data.value})} value={this.state.name} />
						<Form.Input label={I18n.t("users_modify.email")} name="email" placeholder='E-mail' type='email' onChange={(e, data) => this.setState({email: data.value})} value={this.state.email} />
						<Form.Group widths="equal">
							<Form.Input autoComplete="new-password" label={I18n.t("users_modify.password")} name="password" type='password' onChange={(e, data) => this.setState({password: data.value})} value={this.state.password}/>
							<Form.Input autoComplete="new-password" label={I18n.t("users_modify.password2")} name="password" type='password' onChange={(e, data) => this.setState({password2: data.value})} value={this.state.password2}/>
						</Form.Group>
						<Form.Select
							fluid
							label={I18n.t("users_modify.role")}
							options={[
								{ key: 'admin', text: 'Admin', value: 'admin' },
								{ key: 'executive', text: 'Executive', value: 'executive' },
								{ key: 'standard', text: 'Standard', value: 'standard' }
							]}
							placeholder='Select Role'
							value={this.state.role}
							onChange={(e, data) => {this.setState({role : data.value})}}
						/>
						
					</Form>
					{this.state.errorMessage ? (
						<Message error>
							<Icon name='exclamation' />
							{this.state.errorMessage}
						</Message>
					) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("users_modify.cancel")}
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content={I18n.t("users_modify.update")}
						color='green'
						icon="checkmark"
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}