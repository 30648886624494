import {
	DEVICE_INFO_SAVE_ACTION,
	DEVICE_INFO_SAVE_FAILURE,
	DEVICE_INFO_SAVE_SUCCESS
} from './actionList';

import axios from 'axios';

import {store} from '../store';
import { user_reauth_request } from './auth-actions';


export const device_info_save_set_pending = () => ({
	type: DEVICE_INFO_SAVE_ACTION
});

export const device_info_save_completed = (res) => ({
	type: DEVICE_INFO_SAVE_SUCCESS,
	response: res
});

export const device_info_save_failed = () => ({
	type: DEVICE_INFO_SAVE_FAILURE
});

export const device_info_save_action = (deviceId, data) => dispatch => {
	dispatch(device_info_save_set_pending());

	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/gateway/update_info/' + deviceId, {
			device_name : data.device_name, 
			device_description : data.device_description, 
			device_city: data.device_city, 
			map_lat: data.map_lat, 
			map_lng: data.map_lng
		},{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(device_info_save_completed(res.data));
				resolve(true);
			} else {
				dispatch(device_info_save_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(device_info_save_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
