import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
    Table,
    Loader,
    Icon,
    Menu,
    Segment,
    Message,
    Grid,
    Progress,
    Input,
	Header,
	Button,
	Label
} from 'semantic-ui-react';

import { Chart } from "react-google-charts";

import { error } from 'react-notification-system-redux';
import { Translate, I18n } from "react-redux-i18n";
import { graph_emeterdata_fetch_request } from '../actions/graphs-actions';

class DeviceDetails_Graphs extends Component{
	constructor(params){
		super(params);
	}

	componentDidMount(){
		this.props.dispatch(graph_emeterdata_fetch_request(this.props.deviceId));
	}
	
	render(){
		let {data, loading} = this.props.graphs;

		return (
			<React.Fragment>
				<Loader active={loading} inline='centered' indeterminate/>

				{!loading && data.eMeterPowerReadings ? (
					<Segment basic style={{padding: 0, margin: 0}}>
						<Chart
							
							height={'400px'}
							chartType="LineChart"
							
							data={data.eMeterPowerReadings}
							options={{
								title: "Sayaç Güç Tüketim Değerleri",
								interpolateNulls: true,
								titleTextStyle: {
									bold: false,
									fontSize: 14
								},
								curveType: 'function',
								vAxis: {
									format: "0.0 W",
									textStyle: {
										fontSize: 11
									},
									viewWindowMode: 'pretty'
								},
								hAxis: {
									showTextEvery: 6,
									slantedText: true,
									textStyle: {
										fontSize: 11
									}
								}
							}}
							rootProps={{ 'data-testid': '3' }}
						/>
					</Segment>
				) : null}

				{!loading && data.eMeterCurrentReadings ? (
					<Segment basic style={{padding: 0, margin: 0}}>
						<Chart
							
							height={'400px'}
							chartType="LineChart"
							
							data={data.eMeterCurrentReadings}
							options={{
								title: "Sayaç Akım Değerleri",
								interpolateNulls: true,
								titleTextStyle: {
									bold: false,
									fontSize: 14
								},
								curveType: 'function',
								vAxis: {
									format: "0.0 mA",
									textStyle: {
										fontSize: 11
									},
									viewWindowMode: 'pretty'
								},
								hAxis: {
									showTextEvery: 6,
									slantedText: true,
									textStyle: {
										fontSize: 11
									}
								}
							}}
							rootProps={{ 'data-testid': '1' }}
						/>
					</Segment>
				) : null}

				{!loading && data.eMeterVoltageReadings ? (
					<Segment basic style={{padding: 0, margin: 0}}>
						<Chart
							
							height={'400px'}
							chartType="LineChart"
							
							data={data.eMeterVoltageReadings}
							options={{
								title: "Sayaç Gerilim Değerleri",
								interpolateNulls: true,
								titleTextStyle: {
									bold: false,
									fontSize: 14
								},
								curveType: 'function',
								vAxis: {
									format: "0 V",
									textStyle: {
										fontSize: 11
									},
									viewWindowMode: 'pretty'
								},
								hAxis: {
									showTextEvery: 6,
									slantedText: true,
									textStyle: {
										fontSize: 11
									}
								}
							}}
							rootProps={{ 'data-testid': '2' }}
						/>
					</Segment>
				) : null}

				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	graphs: state.graphs
})
export default connect(mapStateToProps)(DeviceDetails_Graphs)