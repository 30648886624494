import {
	DEVICE_DETAILS_ACTION,
	DEVICE_DETAILS_SUCCESS,
	DEVICE_DETAILS_FAILURE 
} from '../actions/actionList'

const deviceDetails = (state = {data: {}, loading: false}, action) => {
	switch (action.type) {
		case DEVICE_DETAILS_ACTION:
			return {
				...state,
				data: {},
				loading: true
			};
		case DEVICE_DETAILS_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case DEVICE_DETAILS_FAILURE:
			return {
				...state,
				data: {},
				loading: false
			};
		default:{
			return state
		}
			
	}
}

export default deviceDetails;
