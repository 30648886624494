import {
	GRAPH_FETCH_ACTION,
	GRAPH_FETCH_SUCCESS, 
	GRAPH_FETCH_FAILURE 
} from './actionList';
import axios from 'axios';

import {store} from '../store';



export const graph_fetch_set_pending = () => ({
	type: GRAPH_FETCH_ACTION
});

export const graph_fetch_set_successful = (res) => ({
	type: GRAPH_FETCH_SUCCESS,
	response: res
});

export const graph_fetch_set_failed = () => ({
	type: GRAPH_FETCH_FAILURE
});

export const graph_emeterdata_fetch_request = (device_id) => dispatch => {
	dispatch(graph_fetch_set_pending());
	axios.get(process.env.REACT_APP_SERVER_URL + '/graphs/emeterdata/' + device_id, {
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(graph_fetch_set_successful(res.data));
		} else {
			dispatch(graph_fetch_set_failed());
		}
	})
	.catch((err) => {
		dispatch(graph_fetch_set_failed());
	});
}
