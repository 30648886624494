import {
	GWCODE_SINGLE_GET_ACTION,
	GWCODE_SINGLE_GET_SUCCESS,
	GWCODE_SINGLE_GET_FAILURE,
	GWCODE_SINGLE_SAVE_ACTION,
	GWCODE_SINGLE_SAVE_SUCCESS,
	GWCODE_SINGLE_SAVE_FAILURE,
	GWCODE_SINGLE_PUSH_ACTION,
	GWCODE_SINGLE_PUSH_FAILURE,
	GWCODE_SINGLE_PUSH_SUCCESS
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { error, success } from 'react-notification-system-redux';
import { user_reauth_request } from './auth-actions';

export const gwcode_single_get_set_pending = (codeId) => ({
	type: GWCODE_SINGLE_GET_ACTION,
	response: {_id: codeId}
});

export const gwcode_single_get_set_successful = (res) => ({
	type: GWCODE_SINGLE_GET_SUCCESS,
	response: res
});

export const gwcode_single_get_set_failed = () => ({
	type: GWCODE_SINGLE_GET_FAILURE
});





export const gwcode_single_get_request = (codeId) => dispatch => {
	dispatch(gwcode_single_get_set_pending(codeId));
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + '/gateway_codes/get/' + codeId,{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwcode_single_get_set_successful(res.data));
				resolve();
			} else {
				dispatch(gwcode_single_get_set_failed());
				dispatch(error({title: "Error", message: res}));
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwcode_single_get_set_failed());
			dispatch(error({title: "Error", message: JSON.stringify(err)}));
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
};



export const gwcode_single_save_set_pending = () => ({
	type: GWCODE_SINGLE_SAVE_ACTION
});

export const gwcode_single_save_set_successful = (res) => ({
	type: GWCODE_SINGLE_SAVE_SUCCESS
});

export const gwcode_single_save_set_failed = () => ({
	type: GWCODE_SINGLE_SAVE_FAILURE
});

export const gwcode_single_save_request = (codeId, code) => dispatch => {
	dispatch(gwcode_single_save_set_pending());
	axios.post(process.env.REACT_APP_SERVER_URL + '/gateway_codes/save/' + codeId,{
		code: code
	}, {
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	},
	)
	.then(res => {
		if (res.status === 200) {
			dispatch(gwcode_single_save_set_successful(res.data));
			dispatch(success({title: "Save Completed",  position: 'br'}));
		} else {
			dispatch(gwcode_single_save_set_failed());
			dispatch(error({title: "Error", message: res, position: 'br'}));
		}
	})
	.catch((err) => {
		dispatch(gwcode_single_save_set_failed());
		dispatch(error({title: "Error", message: JSON.stringify(err)}));
		if (err.response && err.response.status && err.response.status === 401) {
			dispatch(user_reauth_request(store.getState().auth.token));
		}
	});
};


export const gwcode_single_push_set_pending = () => ({
	type: GWCODE_SINGLE_PUSH_ACTION
});
export const gwcode_single_push_set_successful = () => ({
	type: GWCODE_SINGLE_PUSH_FAILURE
});
export const gwcode_single_push_set_failed = () => ({
	type: GWCODE_SINGLE_PUSH_SUCCESS
});

export const gwcode_single_push_request = (codeId) => dispatch => {
	dispatch(gwcode_single_push_set_pending());
	axios.get(process.env.REACT_APP_SERVER_URL + '/gateway_codes/update_gateways/' + codeId, {
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	},
	)
	.then(res => {
		if (res.status === 200) {
			dispatch(gwcode_single_push_set_successful(res.data));
			dispatch(success({title: "Operation Completed",  position: 'br'}));
		} else {
			dispatch(gwcode_single_push_set_failed());
			dispatch(error({title: "Error", message: res, position: 'br'}));
		}
	})
	.catch((err) => {
		dispatch(gwcode_single_push_set_failed());
		dispatch(error({title: "Error", message: JSON.stringify(err)}));
		if (err.response && err.response.status && err.response.status === 401) {
			dispatch(user_reauth_request(store.getState().auth.token));
		}
	});
};