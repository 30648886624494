import {
	DEVICE_DELETE_ACTION,
	DEVICE_DELETE_SUCCESS,
	DEVICE_DELETE_FAILURE,
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { user_reauth_request } from './auth-actions';
import { device_list_refresh_request } from './devices-actions';


export const device_delete_set_pending = () => ({
	type: DEVICE_DELETE_ACTION
});

export const device_delete_set_successful = () => ({
	type: DEVICE_DELETE_SUCCESS
});

export const device_delete_set_failed = () => ({
	type: DEVICE_DELETE_FAILURE
});

export const device_delete_request = (deviceId) => dispatch => {
	dispatch(device_delete_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(
			process.env.REACT_APP_SERVER_URL + '/gateway/delete/' + deviceId,
			{
				headers: {
					Authorization: 'Bearer ' + store.getState().auth.token
				}
			}
		)
		.then(res => {
			if (res.status === 200) {
				dispatch(device_delete_set_successful());
				dispatch(device_list_refresh_request());
				resolve();
			} else {
				dispatch(device_delete_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(device_delete_set_failed());
			reject(err);
		});
	});
}
