import {
	GWCODES_LIST_ACTION,
	GWCODES_LIST_SUCCESS,
	GWCODES_LIST_FAILURE
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { error, success } from 'react-notification-system-redux';



export const gwcode_list_set_pending = () => ({
	type: GWCODES_LIST_ACTION
});

export const gwcode_list_set_successful = (res) => ({
	type: GWCODES_LIST_SUCCESS,
	response: res
});

export const gwcode_list_set_failed = () => ({
	type: GWCODES_LIST_FAILURE
});

export const gwcode_list_request = () => dispatch => {
	dispatch(gwcode_list_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(process.env.REACT_APP_SERVER_URL + '/gateway_codes/list',{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(gwcode_list_set_successful(res.data));
				resolve();
			} else {
				dispatch(gwcode_list_set_failed());
				dispatch(error({title: "Error", message: res}));
				reject();
			}
		})
		.catch((err) => {
			dispatch(gwcode_list_set_failed());
			dispatch(error({title: "Error", message: JSON.stringify(err)}));
			reject();
		});
	});
}