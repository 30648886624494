import React, { Component } from 'react';
import { Segment, Button, Form, Icon, Message, Grid, Header } from 'semantic-ui-react'
import { user_login_request, user_logout_request } from '../actions/auth-actions';
import { connect } from 'react-redux'
import { css, StyleSheet } from 'aphrodite';


class Login extends Component{
	constructor(props) {
		super(props);
		this.state={
			email: "",
			password: ""
		}
	}

	onFormSubmit(){
		this.props.dispatch(user_login_request(this.state.email, this.state.password));
	}
	requestLogOut(){
		this.props.dispatch(user_logout_request());
	}

	handleChange(e, data){
		switch(data.name){
			case "email": this.setState({email: data.value}); break;
			case "password": this.setState({password: data.value}); break;
			default: {}
		}
	}

	render(){
		let {status, explanation} = this.props.auth;
		return (
			<Grid className={css(styles.grid)}>
				<Grid.Row className={css(styles.row)}>
					<Grid.Column mobile={16} tablet={7} computer={5} largeScreen={4} className={css(styles.col)}>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className={css(styles.row)}>
					<Grid.Column mobile={16} tablet={7} computer={5} largeScreen={4} className={css(styles.col)}>
						<Segment basic>
							<Header as={"h3"} inverted className={css(styles.header)}>
								AKAYSİS<br/>
								Smart Lighting Portal
							</Header>
							
							<Form className='fluid' inverted onSubmit={() => this.onFormSubmit()}>
								<Form.Input label='E-mail' name="email" placeholder='E-mail' type='email' onChange={(e, data) => this.handleChange(e, data)} value={this.state.email} />
								<Form.Input label='Password' name="password" type='password' onChange={(e, data) => this.handleChange(e, data)} value={this.state.password}/>
								<Button floated="right" color='blue' loading={status === "pending"} >Submit</Button>
							</Form>
						</Segment>
						<Segment basic>
							{status === "failed" ? (
								<Message inverted error>
									<Icon name='exclamation' />
									{explanation}
								</Message>
							) : null}
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
})
export default connect(mapStateToProps)(Login)

const styles = StyleSheet.create({
	grid: {
		height: '100%',
		background: "url(bg.jpg)",
		"background-size": "cover",
		"background-repeat": "no-repeat",
		"background-attachment": "fixed",
		"background-position": "top right",
		padding: "0",
		margin: "0",
	},
	col: {
		background: "rgba(0, 0, 0, 0.5)",	
	},
	row: {
		padding: 0,
		margin: 0
	}
})