import {
	USER_LOGIN_ACTION, 
	USER_LOGIN_FAILURE, 
	USER_LOGIN_SUCCESS, 
	USER_LOGOUT_ACTION,
	USER_REAUTH_ACTION,
	USER_REAUTH_SUCCESS,
	USER_REAUTH_FAILURE
} from '../actions/actionList'

const auth = (state = {token: "", email: "", role: ""}, action) => {
	switch (action.type) {
		case USER_LOGIN_ACTION:
			return {
				email: action.email,
				status: "pending",
			};
		case USER_LOGIN_FAILURE:
			return {
				status: "failed",
				explanation: action.response.data.explanation ? action.response.data.explanation : "NO_RESPONSE"
			};
		case USER_LOGIN_SUCCESS: 
			return {
				status: "successful",
				email: action.response.data.email,
				token: action.response.data.token,
				name: action.response.data.name,
				role: action.response.data.role
			}
		case USER_LOGOUT_ACTION: {
			return {
				status: "",
				token: "",
				email: ""
			}
		}
		case USER_REAUTH_ACTION: {
			return {
				...state,
				reauthing: true
			}
		}
		case USER_REAUTH_SUCCESS: {
			return {
				...state,
				status: "successful",
				reauthing: null,
				email: action.response.email,
				name: action.response.name,
				role: action.response.role,
			}
		}
		case USER_REAUTH_FAILURE: {
			return {
				...state,
				reauthing: null,
				status: "failed",
				explanation: "Re-Authing Failed"
			}
		}
		default:{
			return state
		}
			
	}
}

export default auth;
