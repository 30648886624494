import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Checkbox, Select, Message } from 'semantic-ui-react'
import MapComponent from '../components/MapComponent';
import { mesh_item_update_request } from '../actions/mesh-item-update';
import { connect } from 'react-redux';

class DeviceDetails_MeshItems_LocationEditor extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			name: "",
			map_lat: 0,
			map_lng: 0,
			errorMessage: ""
		}
	}

	onOpen(){
		this.setState({
			open: true,
			name: this.props.data.name,
			map_lat: this.props.data.map_lat ? this.props.data.map_lat : 0,
			map_lng: this.props.data.map_lng ? this.props.data.map_lng : 0
		});
	}

	submitForm(){
		console.log("Submit");
		this.props.dispatch(
			mesh_item_update_request(
				this.props.deviceId, 
				this.props.data.mac_addr, 
				{
					...this.state, 
					open: null
				}
			)
		).then((resolved, rejected) => {
			console.log(resolved, rejected);
			if(resolved){
				this.close();
			}
		})
	}

	close(){
		this.setState({open: false});
	}

	render(){
		return(
			<Modal 
				trigger={this.props.children} 
				onOpen={() => this.onOpen()} 
				open={this.state.open} 
				closeOnDimmerClick={false}  
			>
				<Header icon='tasks' content='Edit Mesh Item Information' />
				<Modal.Content>
				<Form>					
					<Form.Input label='Name' name="name" placeholder='Name' value={this.state.name} onChange={(e, data) => this.setState({name: data.value})} />	
					<MapComponent 
						initialLat={this.state.map_lat} 
						initialLng={this.state.map_lng}
						deviceLat={this.props.device_lat}
						deviceLng={this.props.device_lng}
						onLocationUpdated={(lng, lat) => 
							this.setState({
								map_lat: lat,
								map_lng: lng
							})
						}>
					</MapComponent>
				</Form>

				
				{this.state.errorMessage ? (
					<Message error>
						<Icon name='exclamation' />
						{this.state.errorMessage}
					</Message>
				) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content="Cancel" 
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content="Save" 
						color='green'
						icon="checkmark"
						loading={this.props.loading}
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({ });
export default connect(mapStateToProps)(DeviceDetails_MeshItems_LocationEditor)