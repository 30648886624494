import React, { Component } from 'react';
import { device_get_details, device_refresh_details } from '../actions/device-details-actions';
import { connect } from 'react-redux';
import { Table, Button, Icon, Label, Segment, Message, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { gwcommands_list_request } from '../actions/gwcommands-actions';
import { Translate, I18n } from "react-redux-i18n";
import JSONView from './JSONView';

class DeviceDetails_History extends Component{


	componentDidMount(){
		this.props.dispatch(gwcommands_list_request(this.props.deviceId));
	}


	getReadableTimeString(date){
		let _date = new Date(date);
		return _date.toLocaleDateString("tr-TR") + " " + _date.toLocaleTimeString("tr-TR");
	}

	render(){
		let {data, loading} = this.props.gwcommands;
		return (
			<Segment basic attached="bottom" style={{padding: 0, flex: 1}}>
					<Table fixed celled unstackable style={{margin: 0, paddingRight: 10, border: 'none'}}>
						<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={4} textAlign="center"><Translate value="deviceDetails_Overview.datetime"/></Table.HeaderCell>
							<Table.HeaderCell width={4} textAlign="center"><Translate value="deviceDetails_Overview.command"/></Table.HeaderCell>
							<Table.HeaderCell width={4} textAlign="center"><Translate value="deviceDetails_Overview.user"/></Table.HeaderCell>
							<Table.HeaderCell width={4} textAlign="center"><Translate value="deviceDetails_Overview.status"/></Table.HeaderCell>
						</Table.Row>
						</Table.Header>
					</Table>
					{!loading ? (
						data && data.length > 0 ? (
							<div style={{overflowY: 'auto', overflowX: 'hidden', maxHeight:'calc(100vh - 220px)',overflowY:'auto', scrollY: 'auto'}}>
								<Table fixed celled unstackable style={{margin: 0, border: 'none'}}>
									<Table.Body>
										{data.map(x=>{
											return (<Table.Row key={x._id}>
												<Table.Cell width={4} textAlign="center">{this.getReadableTimeString(x.timestamp)}</Table.Cell>
												<Table.Cell width={4} textAlign="center">{x.command}</Table.Cell>
												<Table.Cell width={4} textAlign="center"><Translate value={x.user || "unknown"}/></Table.Cell>
												<Table.Cell width={4} textAlign="center">
													<Button.Group size="mini">
														<Button color={x.status == "completed" ? "green" : (x.status == "failed" ? "red" : "blue")}><Translate value={`deviceDetails_Overview.cmd_status_${x.status}`} /></Button>
														{x.payload ? (
															<JSONView title={x._id} data={x}><Button color={x.status == "completed" ? "green" : (x.status == "failed" ? "red" : "blue")} icon="content"></Button></JSONView>
														): null}
													</Button.Group>
												</Table.Cell>
											</Table.Row>)
										})}
									</Table.Body>
								</Table>
							</div>
						) : (
							<Message attached='bottom' warning>
							<Icon name='exclamation' />
								No data found
							</Message>
						) 
					) : (
						<Message attached='bottom'>
						<Icon name='circle notched' loading={true} />
							Loading Data
						</Message>
					)}

				
			</Segment>
		);
	}
}


const mapStateToProps = state => ({
	deviceDetails: state.deviceDetails,
	gwcommands: state.gwcommands
})
export default connect(mapStateToProps)(DeviceDetails_History)