import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '../reducers'
import { SetTransform } from './transforms';
import { user_reauth_request } from '../actions/auth-actions';
import reduxWebsocket, {connect} from '@giantmachines/redux-websocket';
import { wsMiddleware } from '../middlewares/ws';

const persistConfig = {
	key: 'root',
	storage,
	transforms: [SetTransform],
	whitelist: ['auth', 'app', 'i18n']
  }
const persistedReducer = persistReducer(persistConfig, rootReducer);
const reduxWebsocketMiddleware = reduxWebsocket();

const composeEnhancers = composeWithDevTools({
	trace: true, traceLimit: 25
});
export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunk, reduxWebsocketMiddleware, wsMiddleware))
)
export const persistor = persistStore(store, {}, () => {
	if(store.getState().auth.token && store.getState().auth.token !== "")
		store.dispatch(user_reauth_request(store.getState().auth.token));
});