import {
	MESH_ITEM_DELETE_ACTION,
	MESH_ITEM_DELETE_SUCCESS, 
	MESH_ITEM_DELETE_FAILURE
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { device_list_refresh_request } from './devices-actions';


export const mesh_item_delete_set_pending = () => ({
	type: MESH_ITEM_DELETE_ACTION
});

export const mesh_item_delete_set_successful = () => ({
	type: MESH_ITEM_DELETE_SUCCESS
});

export const mesh_item_delete_set_failed = () => ({
	type: MESH_ITEM_DELETE_FAILURE
});

export const mesh_item_delete_request = (deviceId, mac_addr) => dispatch => {
	console.log("mesh_item_delete_request");
	dispatch(mesh_item_delete_set_pending());
	return new Promise((resolve, reject) => {
		axios.get(
			process.env.REACT_APP_SERVER_URL + '/gateway/delete_mesh/' + deviceId + '/' + mac_addr,
			{
				headers: {
					Authorization: 'Bearer ' + store.getState().auth.token
				}
			}
		)
		.then(res => {
			if (res.status === 200) {
				dispatch(mesh_item_delete_set_successful());
				dispatch(device_list_refresh_request());
				resolve();
			} else {
				dispatch(mesh_item_delete_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(mesh_item_delete_set_failed());
			reject(err);
		});
	});
}
