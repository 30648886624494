import React, { Component } from 'react';
import { device_list_request, device_list_refresh_request} from '../actions/devices-actions';
import { Header,Message, Table, Breadcrumb, Label, Icon, Menu, Segment, Button, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from "react-redux-i18n";
import { Default } from '../components/responsive';



export class Devices extends Component{
	refreshDevices(){
		this.props.dispatch(device_list_refresh_request());
	}

	componentDidMount(){
		this.props.dispatch(device_list_request());
	}

	

	render(){
		
		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="server"></Icon>
							<Header.Content><Translate value="devices.header" /></Header.Content>
							{/* <Header.Subheader>A header can be attached to other content, like a segment.</Header.Subheader> */}
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
								<Menu.Item
									name='Delete Device'
									onClick={() => {this.refreshDevices()}}
								>
									<Icon name="refresh" />
									<Translate value="devices.refreshButton" />
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{paddingTop: 0}}>
						<Grid.Column>
							<Segment style={{padding: 0}}>
								<Table unstackable attached basic style={{margin: 0}}>
									<Table.Header>
									<Table.Row>
										<Default><Table.HeaderCell><Translate value="devices.deviceCity" /></Table.HeaderCell></Default>
										<Table.HeaderCell><Translate value="devices.deviceName" /></Table.HeaderCell>
										<Default><Table.HeaderCell><Translate value="devices.macAddress" /></Table.HeaderCell></Default>
										<Table.HeaderCell><Translate value="devices.deviceStatus" /></Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.props.devices.data.map(x=>{
											return (<Table.Row key={x._id}>
												<Default><Table.Cell>{x.city}</Table.Cell></Default>
												<Table.Cell>{x.device_name}</Table.Cell>
												<Default><Table.Cell>{x.mac_addr}</Table.Cell></Default>
												<Table.Cell><Label color={x.device_status == "Active" ? "green" : "red"}><Translate value={`deviceDetails_Overview.status_${x.device_status}`} /></Label></Table.Cell>
												<Table.Cell><Button as={Link} size="tiny" primary to={"/devices/" + x._id}><Translate value="devices.detailsButton" /></Button></Table.Cell>
											</Table.Row>)
										})}
									</Table.Body>					
								</Table>
								{
									this.props.devices.loading ? (
										<Segment secondary attached='bottom'>
										<Icon name='circle notched' loading />
											<Translate value="application.loadingData" />
										</Segment>
									): null 
								}

								{
									!this.props.devices.loading && this.props.devices.data && this.props.devices.data.length == 0 ? (
										<Segment secondary attached='bottom'>
										<Icon name='exclamation' />
											<Translate value="devices.noDeviceAllowed" />
										</Segment>
									): null 
								}
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>


				
				
				
				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	devices: state.devices
})
export default connect(mapStateToProps)(Devices)