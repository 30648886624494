import {
	USERS_LIST_ACTION,
	USERS_LIST_SUCCESS,
	USERS_LIST_FAILURE,
	USER_DELETE_ACTION
} from '../actions/actionList'

const users = (state = {data: [], loading: false}, action) => {
	switch (action.type) {
		case USERS_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case USERS_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case USERS_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		case USER_DELETE_ACTION: 
			return {
				...state,
				data: state.data.map(x=> {
					if(x._id === action.userId)
						return {loading: true, ...x};
					else 
					return x;
				})
			};
		default:{
			return state
		}
			
	}
}

export default users;
