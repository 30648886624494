import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
    Table,
    Loader,
    Icon,
    Menu,
    Segment,
    Message,
    Grid,
    Progress,
    Dropdown,
	Popup,
	Button,
	Label
} from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { gwcommands_add_request } from '../actions/gwcommands-actions';
import { mesh_item_delete_request } from '../actions/mesh-item-delete';
import { error } from 'react-notification-system-redux';
import { Translate, I18n } from "react-redux-i18n";
import { debounce } from 'lodash';

import { Slider } from "react-semantic-ui-range-fs";
import { Default, Mobile } from '../components/responsive';
import DeviceDetails_MeshItems_LocationEditor from './DeviceDetails.MeshItems.LocationEditor';

class DeviceDetails_MeshItems extends Component{
	constructor(params){
		super(params);
		this.state = {
			editMode: false,
			temp_meshValue: 0
		}
	}

	percentFormatter(v) {
		return `${v} %`;
	}

	setBrightness(macs, value){
		console.log("setBrightness", macs, value);
		this.props.dispatch(gwcommands_add_request(this.props.deviceId, "set_mesh_status", {macs: macs, cid: 1, value: parseInt(value)}));
	}

	setBrightnessDebounced = debounce(this.setBrightness, 500, {leading: true});

	setMode(macs, value){
		this.props.dispatch(gwcommands_add_request(this.props.deviceId, "set_mesh_status", {macs: macs, cid: 0, value: parseInt(value)}));
	}

	setEditMode(nextVal){
		if(nextVal){
			if(this.props.deviceDetails.data && this.props.deviceDetails.data.device_status && this.props.deviceDetails.data.device_status === "Active"){
				this.setState({editMode :true});
			} else {
				this.props.dispatch(error({title: I18n.t("deviceDetails_Overview.device_inactive_title"), message: I18n.t("deviceDetails_Overview.device_inactive_msg")}));
			}
		} else {
			this.setState({editMode: false});
		}
	}

	render(){
		let {mesh_items, device_status, map_lat, map_lng} = this.props.deviceDetails.data;
		console.log("render");
	
		return (
			<div>
				<Loader active={this.props.deviceDetails.loading} inline='centered' indeterminate/>
				<Segment basic style={{padding: 0, margin: 0}}>
						<Segment style={{padding: 0, flex: 1}}>
							{mesh_items && mesh_items.length ? (
								<Table style={{margin: 0, border: 'none'}} fixed unstackable>
									<Table.Header>
									<Mobile>
										<Table.Row>
											<Table.HeaderCell colSpan={3}>
												<Button 
													style={{marginTop: -5}}
													size="tiny"
													onClick={() => this.setEditMode(!this.state.editMode)} 
													color={this.state.editMode ? "green" : null} 
													floated="right"
													disabled={device_status !== "Active"}>
														<Translate value="deviceDetails_Overview.commandModeButton" />
												</Button>
											</Table.HeaderCell>
										</Table.Row>
									</Mobile>
									<Table.Row>
										<Table.HeaderCell textAlign="center" width={2}><Translate value="deviceDetails_Overview.deviceName" /></Table.HeaderCell>
										<Table.HeaderCell textAlign="center" width={3}><Translate value="deviceDetails_Overview.deviceModel" /></Table.HeaderCell>
										<Table.HeaderCell textAlign="center" width={1}><Translate value="deviceDetails_Overview.firmwareVersion" /></Table.HeaderCell>
										<Default>
											<Table.HeaderCell textAlign="center" width={2}><Translate value="deviceDetails_Overview.signalStrength" /></Table.HeaderCell>
											
											<Table.HeaderCell textAlign="center" width={1}><Translate value="deviceDetails_Overview.layer" /></Table.HeaderCell>
											<Table.HeaderCell width={7}>
												<Translate value="deviceDetails_Overview.deviceBrightness" />
												<Button 
													style={{marginTop: -5}}
													size="tiny"
													onClick={() => this.setEditMode(!this.state.editMode)} 
													color={this.state.editMode ? "green" : null} 
													floated="right"
													disabled={device_status !== "Active"}>
														<Translate value="deviceDetails_Overview.commandModeButton" />
												</Button>
											</Table.HeaderCell>
										</Default>
									</Table.Row>
									</Table.Header>
									<Table.Body>
										{this.state.editMode ? (
											<Table.Row warning>
												<Default>
													<Table.Cell colSpan={5}><Translate value="deviceDetails_Overview.allNodesHeader" /></Table.Cell>
													<Table.Cell>
													<Popup position='left center' content={this.state.temp_meshValue + "%"} trigger={
														<div>
															<Grid>
																<Grid.Column width={12}>
																	<Slider discrete value={0} color="black" settings={{start: 0, min: 0, max: 100, step: 5, 
																		onChange: (val) => {
																			this.setState({temp_meshValue: val})
																		},
																		onChangeCommited: (val) => {
																			this.setBrightnessDebounced("all", val);
																		}
																	}} />
																</Grid.Column>
																<Grid.Column width={4}>
																	
																</Grid.Column>
															</Grid>
														</div>
													} />
												</Table.Cell>
												</Default>
												<Mobile>
													<Table.Cell><Translate value="deviceDetails_Overview.allNodesHeader" /></Table.Cell>
													<Table.Cell colSpan={2}>
													<Popup position='left center' content={parseInt(this.state.temp_meshValue) + "%"} trigger={
														<div>
															<Grid>
																<Grid.Column width={12}>
																	<Slider discrete value={0} color="black" settings={{start: 0, min: 0, max: 100, step: 5, 
																		onChange: (val) => {
																			this.setState({temp_meshValue: val})
																		},
																		onChangeCommited: (val) => {
																			this.setBrightnessDebounced("all", val);
																		}
																	}} />
																</Grid.Column>
																<Grid.Column width={4}>
																	
																</Grid.Column>
															</Grid>
														</div>
													} />
												</Table.Cell>
												</Mobile>
												
											</Table.Row>
										) : null}
										{mesh_items.sort((a, b) => (a.connected > b.connected) ? -1 : 1).map(x=>{
											return (
											<React.Fragment key={x._id}>
												<Table.Row key={x._id}>
													<Default>
														<Table.Cell textAlign="center" style={{overflow:'visible'}}>
														<Button.Group size="mini" color={x.connected ? "green" : "red"}>
															<Button>{x.name}</Button>
															{!x.connected ? (
																<Button icon="remove" onClick={() => {
																	this.props.dispatch(mesh_item_delete_request(this.props.deviceId, x.mac_addr));
																}}></Button>
															): null}
															<DeviceDetails_MeshItems_LocationEditor
															deviceId={this.props.deviceId}
															device_lat={map_lat}
															device_lng={map_lng}
															data={x}>
																<Button size="tiny" icon>
																	<Icon name="point"/>
																</Button>
															</DeviceDetails_MeshItems_LocationEditor>
														</Button.Group>
														</Table.Cell>
													</Default>
													<Mobile>
														<Table.Cell rowSpan={2} textAlign="center">
															<Label color={x.connected ? "green" : "red"}>
																{x.name}
															</Label>
														</Table.Cell>
													</Mobile>

													<Table.Cell textAlign="center">M-72S-60-AC[4000-AS]</Table.Cell>
													<Table.Cell textAlign="center">{x.version}</Table.Cell>
													<Default>
														<Table.Cell textAlign="center">{x.rssi}</Table.Cell>	
														<Table.Cell textAlign="center">{x.layer}</Table.Cell>
													
														<Table.Cell>{
															this.state.editMode ? (
																<React.Fragment>
																	<Grid>
																		<Grid.Column width={12}>
																			<Popup position='left center' content={parseInt(this.state.temp_meshValue) + "%"} trigger={
																				<div>
																					<Slider 
																						discrete 
																						color="black" 
																						disabled={!x.connected} 
																						value={x.brightness == 0 ? 0.001 : x.brightness} //Value bug turn-around
																						settings={{
																							min: 0, 
																							max: 100, 
																							step: 5, 
																							onChange: (val) => {
																								this.setState({temp_meshValue: val})
																							},
																							onChangeCommited: (val) => {
																								this.setBrightnessDebounced(x.mac_addr, val);
																							}
																						}} 
																					/>
																				</div>
																			} />
																		</Grid.Column>
																		<Grid.Column width={4}>
																			<Label>{x.brightness} %</Label>
																		</Grid.Column>
																	</Grid>
																	
																	
																</React.Fragment>
															) : (
																<Progress style={{margin: 0}} progress color={x.connected ? "teal" : "grey"} percent={x.connected ? x.brightness : 0} />
															)
														}</Table.Cell>
													</Default>
												</Table.Row>
												<Mobile>
													<Table.Row>
														<Table.Cell colSpan={2}>
														{
															this.state.editMode ? (
																<React.Fragment>
																	<Grid>
																		<Grid.Column width={12}>
																			<Popup position='left center' content={this.state.temp_meshValue + "%"} trigger={
																				<div>
																					<Slider 
																						discrete 
																						color="black" 
																						disabled={!x.connected} 
																						value={x.brightness == 0 ? 0.001 : x.brightness} //Value bug turn-around
																						settings={{
																							min: 0, 
																							max: 100, 
																							step: 5, 
																							onChange: (val) => {
																								this.setState({temp_meshValue: val})
																							},
																							onChangeCommited: (val) => {
																								this.setBrightnessDebounced(x.mac_addr, val);
																							}
																						}} 
																					/>
																				</div>
																			} />
																		</Grid.Column>
																		<Grid.Column width={4}>
																			<Label>{x.brightness} %</Label>
																		</Grid.Column>
																	</Grid>
																	
																	
																</React.Fragment>
															) : (
																<Progress style={{margin: 0}} progress='value' color={x.connected ? "teal" : "grey"} value={x.connected ? x.brightness : 0} total={100} />
															)
														}
														</Table.Cell>
													</Table.Row>
												</Mobile>
											</React.Fragment>
											)
										})}
									</Table.Body>
								</Table>
							) : null }	
						</Segment>
				</Segment>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	deviceDetails: state.deviceDetails
})
export default connect(mapStateToProps)(DeviceDetails_MeshItems)