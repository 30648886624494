import {
	DEVICE_AUTH_USER_ADD_ACTION,
	DEVICE_AUTH_USER_REMOVE_ACTION,
	DEVICE_AUTH_USER_CHANGE_COMPLETED,
	DEVICE_AUTH_USER_CHANGE_FAILED
} from './actionList';

import axios from 'axios';

import {store} from '../store';
import { user_reauth_request } from './auth-actions';


export const device_auth_user_add_set_pending = () => ({
	type: DEVICE_AUTH_USER_ADD_ACTION
});

export const device_auth_user_remove_set_pending = () => ({
	type: DEVICE_AUTH_USER_REMOVE_ACTION
});

export const device_auth_user_set_completed = (res) => ({
	type: DEVICE_AUTH_USER_CHANGE_COMPLETED,
	response: res
});

export const device_auth_user_set_failed = () => ({
	type: DEVICE_AUTH_USER_CHANGE_FAILED
});

export const device_auth_user_action = (deviceId, userId, addOrRemove = false) => dispatch => {
	if(addOrRemove){
		dispatch(device_auth_user_add_set_pending());
	} else {
		dispatch(device_auth_user_remove_set_pending());
	}

	return new Promise((resolve, reject) => {
		axios.post(process.env.REACT_APP_SERVER_URL + '/gateway/update_auth/' + deviceId, {
			operation: addOrRemove ? "add" : "remove",
			user_id: userId
	
		},{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(device_auth_user_set_completed());
				resolve(true);
			} else {
				dispatch(device_auth_user_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(device_auth_user_set_failed());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	});
}
