import {
	APP_SIDEBAR_TOGGLE
} from '../actions/actionList'

const app = (state = {sidebar: true}, action) => {
	switch (action.type) {
		case APP_SIDEBAR_TOGGLE:
			return {
				...state,
				sidebar: !state.sidebar
			};
		default:{
			return state
		}

	}
}

export default app;
