import { createTransform } from 'redux-persist';

export const SetTransform = createTransform(
  (inboundState, key) => {
    return { ...inboundState};
  },
  (outboundState, key) => {
    delete(outboundState.reauthing);
    delete(outboundState.explanation);
    delete(outboundState.email);
    delete(outboundState.name);
    return { ...outboundState, 
      status: outboundState.token && outboundState.token !== "" ? "reauth" : "",
      reauthing: outboundState.token && outboundState.token !== ""
    };
  },
  { whitelist: ['auth'] }
);