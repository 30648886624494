import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { device_list_request, device_list_refresh_request} from '../actions/devices-actions';
import { Header,Message, Table, Breadcrumb, Label, Icon, Menu, Segment, Button, Grid, Card, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from "react-redux-i18n";
import ReactMapboxGl, { Layer, Feature, Marker, Cluster } from 'react-mapbox-gl';

const Map = ReactMapboxGl({
	accessToken: 'pk.eyJ1IjoiZnVhdHNlbmd1bCIsImEiOiJjazgzZ3lsMG4wNDZ2M2VqdTJxem1hbXd2In0.SFayuiX3qPQAp_nk_i9mjw'
});

class Dashboard extends Component{
	constructor(props){
		super(props);
	}


	componentDidMount(){
		this.props.dispatch(device_list_request());
	}
	
	clusterMarker(coordinates, pointCount){
		return (
			<Marker coordinates={coordinates}>
					<Label circular>{pointCount}</Label>
			</Marker>
		);
	}

	render(){
		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="server"></Icon>
							<Header.Content><Translate value="dashboard.header" /></Header.Content>
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
								<Menu.Item
									name='Delete Device'
									onClick={() => {}}
								>
									<Icon name="refresh" />
									<Translate value="devices.refreshButton" />
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{paddingTop: 0}}>
						<Grid.Column>
							<Segment style={{padding: 0, overflow: 'hidden'}}>
								<Map
									style="mapbox://styles/mapbox/streets-v9"
									center={[32.85427, 39.91987]}
									zoom={[17]}

									containerStyle={{
										margin: 1,
										borderRadius: 2,
										height: 'calc(100vh - 86px)',
									}}
									>
										{/* <Cluster ClusterMarkerFactory={this.clusterMarker}> */}
											{this.props.devices.data.map(device => {
												if(device.map_lat && device.map_lng){
													return (
														<React.Fragment>
														<Popup
														key={device._id}
														content={
															<div>
																<h4>Gateway&nbsp;<Label size="tiny" color={device.device_status == "Active" ? "green" : "red"}><Translate value={`deviceDetails_Overview.status_${device.device_status}`} /></Label></h4>
																{device.device_name + "/" + device.device_name}
															</div>
														}
														trigger={
															<Marker
																coordinates={[device.map_lng, device.map_lat]}>
																	<Icon name="point" color="red" size="large"/>
															</Marker>} 
														/>
														{device.mesh_items.map(item => {
															if(item.map_lat && item.map_lng){
																return (
																	<Popup key={item._id} content={<div><h4>Mesh Item</h4>{device.device_name + "/" + item.name}</div>} trigger={
																		<Marker
																			coordinates={[item.map_lng, item.map_lat]}>
																				<Icon name="point" color="blue" size="large"/>
																		</Marker>} 
																	/>
																);
															}
														})}
														</React.Fragment>
													);
												}
											})	
											}
											{/* </Cluster> */}
										
										
										
								</Map>	
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>


				
				
				
				
			</React.Fragment>
				
		);
	}
}


const styles = StyleSheet.create({
	clusterMarker: {
		width: 30,
		height: 30,
		borderRadius: '50%',
		backgroundColor: '#00FF00',
		display: 'flex',
		justifyContent: 'center',
	},
	marker: {
		width: 25,
		height: 25,
		borderRadius: '50%',
		backgroundColor: '#EEEEEE',
		border: '3px solid red',
		display: 'flex',
		justifyContent: 'center',
		verticalAlign: 'center',
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'red'
	}
});

const mapStateToProps = state => ({
	devices: state.devices
});



export default connect(mapStateToProps)(Dashboard)