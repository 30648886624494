// @flow
import React, { Component } from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Dimmer, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';

import SideMenu from './components/SideMenu';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Devices from './pages/Devices';
import Users from './pages/Users';
import Notifications from 'react-notification-system-redux';
import DeviceDetails from './pages/DeviceDetails';
import GWCodeEditor from './pages/GWCodeEditor';
import { connect as wsConnect, disconnect as wsDisconnect } from '@giantmachines/redux-websocket';
import { store } from './store';

import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import { tr } from './lang/tr';
import { en } from './lang/en';



const appHistory = createBrowserHistory();

type Props = {
	auth: Object,
	notifications: Object
};
class App extends Component<Props> {

	componentDidMount(){
		console.log(process.env);
		store.dispatch(wsConnect(String(process.env.REACT_APP_SERVER_URL).replace("https", "wss").replace("http", "ws") + "/ws"));

		syncTranslationWithStore(store)
		store.dispatch(loadTranslations({
			tr: tr,
			en: en
		}));
		if(!store.getState().i18n.locale){
			store.dispatch(setLocale("tr"));
		}
	}

	componentWillUnmount(){
		store.dispatch(wsDisconnect());
	}

	render() {
		let {reauthing} = this.props.auth;
		if(reauthing) return (
			<Dimmer active inverted>
				<Loader inverted>Reauthing</Loader>
			</Dimmer>
		)
		else 
			return (
				<Router history={appHistory}>
					
					{this.props.auth.status === "successful" ? 
						<SideMenu>
							<Route exact path="/" render={() => <Redirect to={{ pathname: "/devices" }} />} />			
							<Route exact path="/profile" component={Profile} />			
							<Route exact path="/devices" component={Devices} />			
							<Route exact path="/dashboard" component={Dashboard} />			
							<Route exact path="/devices/:deviceId" component={DeviceDetails} />			
							<Route exact path="/users" component={Users} />	
							<Route exact path="/gwcode_editor/" component={GWCodeEditor} />
							<Route exact path="/gwcode_editor/:codeId" component={GWCodeEditor} />
						</SideMenu>
					: <Redirect to={{ pathname: "/login" }} />}

					<Route exact path="/login" render={() => {
						return this.props.auth.status !== "successful" ?
							<Login /> :
							<Redirect to={{ pathname: "/" }} />
					}} />	

					<Notifications
						notifications={this.props.notifications}
					/>
				</Router>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	notifications: state.notifications
})
export default connect(mapStateToProps)(App)