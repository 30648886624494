import {
	GWPROGRAMS_LIST_ACTION,
	GWPROGRAMS_LIST_SUCCESS,
	GWPROGRAMS_LIST_FAILURE,
	GWPROGRAMS_LIST_ADD_ACTION,
	GWPROGRAMS_LIST_ADD_SUCCESS,
	GWPROGRAMS_LIST_ADD_FAILURE,
	GWPROGRAMS_LIST_CHANGE_FIELD
} from '../actions/actionList'

const gwprograms = (state = {data: [], loading: false}, action) => {
	switch (action.type) {
		case GWPROGRAMS_LIST_ACTION:
			return {
				...state,
				data: [],
				loading: true
			};
		case GWPROGRAMS_LIST_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case GWPROGRAMS_LIST_FAILURE:
			return {
				...state,
				data: [],
				loading: false
			};
		case GWPROGRAMS_LIST_ADD_ACTION: 
			return {
				...state,
				loading: true
			};
		case GWPROGRAMS_LIST_ADD_SUCCESS:
		case GWPROGRAMS_LIST_ADD_FAILURE: 
			return {
				...state,
				loading: false
			};
		case GWPROGRAMS_LIST_CHANGE_FIELD: 
			return {
				...state,
				//tbc
			}
		default:{
			return state
		}
			
	}
}

export default gwprograms;
