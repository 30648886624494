import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Checkbox, Select, Message } from 'semantic-ui-react'
import { cities_list_request } from '../actions/city-actions';
import { connect } from 'react-redux';
import { device_info_save_action } from '../actions/device-info-save-actions';
import { device_refresh_details } from '../actions/device-details-actions';
import MapComponent from '../components/MapComponent';

export class DeviceDetails_Information_Edit extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			device_name: "",
			device_description: "",
			device_city: "",
			map_lat: 0,
			map_lng: 0,
			city: "",
			errorMessage: ""
		}
	}

	onOpen(){
		this.props.dispatch(cities_list_request());
		this.setState({
			open: true,
			device_name: this.props.data.device_name,
			device_description: this.props.data.device_description ? this.props.data.device_description : "",
			device_city: this.props.data.device_city ? this.props.data.device_city : "",
			map_lat: this.props.data.map_lat ? this.props.data.map_lat : "",
			map_lng: this.props.data.map_lng ? this.props.data.map_lng : "",
			city: this.props.data.city
		});
	}

	submitForm(){
		console.log("Submit");
		this.props.dispatch(device_info_save_action(this.props.data._id, {...this.state, open: null}))
		.then((resolved , rejected) => {
			if(resolved){
				// this.props.dispatch(device_refresh_details(this.props.data._id));
				this.close();
			}
		})
	}

	close(){
		this.setState({open: false});
	}

	render(){
		let cityOptions = [];
		this.props.cities.data.forEach(city => {
			cityOptions.push({
				key: city.code,
				text: city.name,
				value: city._id
			});
		})
		return(
			<Modal 
				trigger={this.props.children} 
				onOpen={() => this.onOpen()} 
				open={this.state.open} 
				closeOnDimmerClick={false}  
			>
				<Header icon='tasks' content='Edit Device Information' />
				<Modal.Content>
				<Form>					
					<Form.Input label='Device Name' name="device_name" placeholder='Device Name' value={this.state.device_name} onChange={(e, data) => this.setState({device_name: data.value})} />	
					<Form.TextArea label='Device Description' name="device_description" placeholder='Device Description' value={this.state.device_description} onChange={(e, data) => this.setState({device_description: data.value})} />
					<Form.Dropdown
						fluid
						search
						name="device_city"
						label='City'
						options={cityOptions}
						selection
						clearable
						placeholder='Select City'
						value={this.state.device_city}
						onChange={(e, data) => {
							this.setState({device_city : data.value ? data.value : ""});
						}}
					/>
					<MapComponent 
						initialLat={this.state.map_lat} 
						initialLng={this.state.map_lng} 
						onLocationUpdated={(lng, lat) => 
							this.setState({
								map_lat: lat,
								map_lng: lng
							})
						}>
					</MapComponent>
				</Form>

				
				{this.state.errorMessage ? (
					<Message error>
						<Icon name='exclamation' />
						{this.state.errorMessage}
					</Message>
				) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content="Cancel" 
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content="Save" 
						color='green'
						icon="checkmark"
						loading={this.props.loading}
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}

const mapStateToProps = state => ({
	cities: state.cities
})
export default connect(mapStateToProps)(DeviceDetails_Information_Edit)