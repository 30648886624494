import { combineReducers } from 'redux'

import {reducer as notifications} from 'react-notification-system-redux';
import { i18nReducer } from 'react-redux-i18n';

import auth from './auth';
import app from './app';
import devices from './devices';
import deviceDetails from './deviceDetails';
import gwcode_single from './gwcode_single';
import gwcodes from './gwcodes';
import users from './users';
import gwcommands from './gwcommands';
import gwprograms from './gwprograms';
import cities from './cities';
import graphs from './graphs';




export default combineReducers({
  app,
  auth,
  notifications,
  devices,
  deviceDetails,
  gwcodes,
  gwcode_single,
  users,
  gwcommands,
  gwprograms,
  cities,
  graphs,
  i18n: i18nReducer
})