import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Checkbox, Divider, Message, Label, Radio } from 'semantic-ui-react'
import { gwprograms_list_add_request } from '../actions/gwprograms-actions';
import { Translate, I18n } from "react-redux-i18n";
import { Slider } from "react-semantic-ui-range-fs";

export default class DeviceDetails_Programs_Create extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false,
			time_type: "User-Defined",
			user_defined_time: "22:00",
			user_defined_offset: 0,
			value_type: "brightness",
			hardswitch: false,
			brightness: 50,
			monday: false,
			tuesday: false,
			wednesday: false,
			thursday: false,
			friday: false,
			saturday: false,
			sunday: false,
			errorMessage: ""
		}
	}



	submitForm(){
		console.log("Submit");
		this.props.dispatch(gwprograms_list_add_request({...this.state, open: null, device_id: this.props.device_id}))
		.then((resolved , rejected) => {
			if(resolved){
				this.close();
			}
		})
	}

	handleChange_user_defined_time(value){
		console.log("State", this.state.user_defined_time);
		console.log("Value", value);
		
		console.log(new Date(value));
	}

	close(){
		this.setState({open: false});
	}

	render(){
		return(
			<Modal 
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				closeOnDimmerClick={false}  
			>
				<Header icon='tasks' content={I18n.t("deviceDetails_Programs.createProgramTitle")} />
				<Modal.Content>
				{this.state.open ? (
					<Form>
						<Form.Group widths="equal">
							<Form.Dropdown
								fluid
								
								name="time_type"
								label={I18n.t("deviceDetails_Programs.timeType")}
								options={[
									{ 
										key: 'User-Defined', 
										text: I18n.t("deviceDetails_Programs.UserDefined"), 
										value: 'User-Defined' 
									},{ 
										key: 'Sunrise', 
										text: I18n.t("deviceDetails_Programs.Sunrise", {time: this.props.city_details && this.props.city_details.sunriseTime}), 
										value: 'Sunrise'
									},{ 
										key: 'Sunset', 
										text: I18n.t("deviceDetails_Programs.Sunset", {time : this.props.city_details && this.props.city_details.sunsetTime}), 
										value: 'Sunset'
									}
								]}
								selection
								placeholder='Select Time Type'
								value={this.state.time_type}
								onChange={(e, data) => {this.setState({time_type : data.value})}}
							/>
							{this.state.time_type !== "User-Defined" ? 
								(   <Form.Input label={I18n.t("deviceDetails_Programs.offset")} name="user_defined_offset" placeholder='Offset Time' type='number' onChange={(e, data) => this.setState({user_defined_offset: parseInt(data.value)})} value={this.state.user_defined_offset} />
								) : <Form.Input label={I18n.t("deviceDetails_Programs.time")} name="user_defined_time" placeholder='User Defined Time' type='time' onChange={(e, data) => this.setState({user_defined_time: data.value})} value={this.state.user_defined_time} /> 
							}
						</Form.Group>
						<Divider horizontal hidden></Divider>
						<Form.Group>
							<Form.Dropdown
									width={4}
									name="value_type"
									label={I18n.t("deviceDetails_Programs.valueType")}
									options={[
										{ 
											key: 'brightness', 
											text: I18n.t("deviceDetails_Programs.value_brightness"), 
											value: 'brightness' 
										},{ 
											key: 'hardswitch', 
											text: I18n.t("deviceDetails_Programs.value_hardswitch"), 
											value: 'hardswitch'
										}
									]}
									selection
									placeholder='Select Value Type'
									value={this.state.value_type}
									onChange={(e, data) => {
										if(data.value === "brightness"){
											this.setState({
												value_type: data.value,
												hardswitch: null,
												brightness: 50
											});
										} else if(data.value === "hardswitch"){
											this.setState({
												value_type: data.value,
												brightness: null,
												hardswitch: false
											});
										}
									}}
							/>
							{this.state.value_type === "brightness" ? ( <React.Fragment>
								<Form.Field 
									width={10}
									control={Slider} 
									label={I18n.t("deviceDetails_Programs.brightness2")}
									settings={{
										start: 50,
										min: 0,
										max: 100,
										step: 1,
										onChange: val => {this.setState({brightness: val})}
									}}
									color="black" value={this.state.brightness}
									style={{marginTop: 12}}
								/>
								<Form.Field 
									label={"\0"}
									width={2}
									icon="lightbulb outline"
									control={Label}
									style={{marginTop: 5}}
									size="large"
								content={this.state.brightness + " %"} />
							</React.Fragment>) : null}

							{this.state.value_type === "hardswitch" ? ( <React.Fragment>
								<Form.Field
									control={Radio}
									name="hardswitch_on"
									label={I18n.t("deviceDetails_Programs.hardswitch_on")}
									checked={this.state.hardswitch}
									onChange={(e, data) => this.setState({hardswitch: data.checked ? true : false})}
									style={{marginTop: 30, paddingLeft: 10}}
								/>
								<Form.Field
									control={Radio}
									name="hardswitch_off"
									label={I18n.t("deviceDetails_Programs.hardswitch_off")}
									checked={!this.state.hardswitch}
									onChange={(e, data) => this.setState({hardswitch: data.checked ? false : true})}
									style={{marginTop: 30, paddingLeft: 10}}
								/>
							</React.Fragment>) : null}
							
						</Form.Group>
						<Divider horizontal hidden></Divider>
						<Header size="tiny"><Translate value="deviceDetails_Programs.appliedDays" /></Header>
						<Form.Group
							widths="equal" 
						>
							<Form.Field
								control={Checkbox}
								name="monday"
								label={I18n.t("deviceDetails_Programs.monday")}
								checked={this.state.monday}
								onChange={(e, data) => this.setState({monday: data.checked})}
							/>
							<Form.Field
								control={Checkbox}
								name="tuesday"
								label={I18n.t("deviceDetails_Programs.tuesday")}
								checked={this.state.tuesday}
								onChange={(e, data) => this.setState({tuesday: data.checked})}
							/>
							<Form.Field
								control={Checkbox}
								name="wednesday"
								label={I18n.t("deviceDetails_Programs.wednesday")}
								checked={this.state.wednesday}
								onChange={(e, data) => this.setState({wednesday: data.checked})}
							/>
							<Form.Field
								control={Checkbox}
								name="thursday"
								label={I18n.t("deviceDetails_Programs.thursday")}
								checked={this.state.thursday}
								onChange={(e, data) => this.setState({thursday: data.checked})}
							/>
							<Form.Field
								control={Checkbox}
								name="friday"
								label={I18n.t("deviceDetails_Programs.friday")}
								checked={this.state.friday}
								onChange={(e, data) => this.setState({friday: data.checked})}
							/>
							<Form.Field
								control={Checkbox}
								name="saturday"
								label={I18n.t("deviceDetails_Programs.saturday")}
								checked={this.state.saturday}
								onChange={(e, data) => this.setState({saturday: data.checked})}
							/>
							<Form.Field
								control={Checkbox}
								name="sunday"
								label={I18n.t("deviceDetails_Programs.sunday")}
								checked={this.state.sunday}
								onChange={(e, data) => this.setState({sunday: data.checked})}
							/>
						</Form.Group>
					</Form>
				): null}
				{this.state.errorMessage ? (
					<Message error>
						<Icon name='exclamation' />
						{this.state.errorMessage}
					</Message>
				) : null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("deviceDetails_Programs.cancelButton")}
						color='red'
						icon="remove"
					/>
					<Button 
						onClick={() => this.submitForm()}
						content={I18n.t("deviceDetails_Programs.createButton")}
						color='green'
						icon="checkmark"
						loading={this.props.loading}
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}