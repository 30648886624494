import {
	GRAPH_FETCH_ACTION,
	GRAPH_FETCH_SUCCESS, 
	GRAPH_FETCH_FAILURE 
} from '../actions/actionList';


const graphs = (state = {data: {}, loading: true}, action) => {
	switch (action.type) {
		case GRAPH_FETCH_ACTION:
			return {
				...state,
				data: {},
				loading: true
			};
		case GRAPH_FETCH_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false
			};
		case GRAPH_FETCH_FAILURE:
			return {
				...state,
				data: {},
				loading: false
			};
		
		default:{
			return state
		}
			
	}
}

export default graphs;