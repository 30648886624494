import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Form, Message } from 'semantic-ui-react'
import { I18n } from 'react-redux-i18n';
import ReactJson from 'react-json-view';

export default class JSONView extends Component{
	constructor(props){
		super(props);

		this.state = {
			open: false
		}
	}

	close(){
		this.setState({open: false});
	}

	syntaxHighlight(json) {
		if (typeof json != 'string') {
			 json = JSON.stringify(json, undefined, 2);
		}
		json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
		return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
			var cls = 'number';
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = 'key';
				} else {
					cls = 'string';
				}
			} else if (/true|false/.test(match)) {
				cls = 'boolean';
			} else if (/null/.test(match)) {
				cls = 'null';
			}
			return '<span class="' + cls + '">' + match + '</span>';
		});
	}

	render(){
		if(this.state.open && this.props.data && this.props.data.payload && this.props.data.payload.macs){
			this.props.data.payload.macs = String("//This data converted into array," + this.props.data.payload.macs).split(',');
		}
		
		return(
			<Modal
				trigger={this.props.children} 
				onOpen={() => this.setState({open: true})} 
				open={this.state.open} 
				size="small"
			>
				<Header icon='code' content={this.props.title} />
				<Modal.Content>
					{this.state.open ? (
						<ReactJson name="Command" src={this.props.data} displayDataTypes={false}/>
					): null}
				</Modal.Content>
				<Modal.Actions>
					<Button 
						onClick={() => this.close()}
						content={I18n.t("action.ok")} 
					/>
				</Modal.Actions>
			</Modal>
		)
	}
}