import {
	MESH_ITEM_UPDATE_ACTION,
	MESH_ITEM_UPDATE_SUCCESS, 
	MESH_ITEM_UPDATE_FAILURE
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { device_list_refresh_request } from './devices-actions';


export const mesh_item_update_set_pending = () => ({
	type: MESH_ITEM_UPDATE_ACTION
});

export const mesh_item_update_set_successful = () => ({
	type: MESH_ITEM_UPDATE_SUCCESS
});

export const mesh_item_update_set_failed = () => ({
	type: MESH_ITEM_UPDATE_FAILURE
});

export const mesh_item_update_request = (deviceId, mac_addr, data) => dispatch => {
	console.log("mesh_item_update_request");
	dispatch(mesh_item_update_set_pending());
	return new Promise((resolve, reject) => {
		axios.post( process.env.REACT_APP_SERVER_URL + '/gateway/update_mesh/' + deviceId + '/' + mac_addr, {
			name: data.name,
			map_lat: data.map_lat,
			map_lng: data.map_lng
		}, {
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status == 200) {
				dispatch(mesh_item_update_set_successful());
				dispatch(device_list_refresh_request());
				resolve(true);
			} else {
				dispatch(mesh_item_update_set_failed());
				reject();
			}
		})
		.catch((err) => {
			dispatch(mesh_item_update_set_failed());
			reject(err);
		});
	});
}
