import {
	DEVICE_LIST_ACTION,
	DEVICE_REFRESH_ACTION,
	DEVICE_LIST_SUCCESS,
	DEVICE_LIST_FAILURE,
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { user_reauth_request } from './auth-actions';


export const device_list_set_pending = () => ({
	type: DEVICE_LIST_ACTION
});

export const device_list_set_refresh = () => ({
	type: DEVICE_REFRESH_ACTION
});

export const device_list_set_successful = (res) => ({
	type: DEVICE_LIST_SUCCESS,
	response: res
});

export const device_list_set_failed = () => ({
	type: DEVICE_LIST_FAILURE
});

export const device_list_request = () => dispatch => {
	dispatch(device_list_set_pending());
	axios.get(process.env.REACT_APP_SERVER_URL + '/gateway/list',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(device_list_set_successful(res.data));
		} else {
			dispatch(device_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(device_list_set_failed());
		if (err.response && err.response.status && err.response.status === 401) {
			dispatch(user_reauth_request(store.getState().auth.token));
		}
	});
}

export const device_list_refresh_request = () => dispatch => {
	dispatch(device_list_set_refresh());
	axios.get(process.env.REACT_APP_SERVER_URL + '/gateway/list',{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(device_list_set_successful(res.data));
		} else {
			dispatch(device_list_set_failed());
		}
	})
	.catch((err) => {
		dispatch(device_list_set_failed());
		if (err.response && err.response.status && err.response.status === 401) {
			dispatch(user_reauth_request(store.getState().auth.token));
		}
	});
}