import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import { store, persistor } from './store'

import * as serviceWorker from './serviceWorker';
import './global.css';

const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
		document.getElementById('root')
	);
};

render(App);
if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}

serviceWorker.unregister();