import {
	GWCODE_SINGLE_GET_ACTION,
	GWCODE_SINGLE_GET_SUCCESS,
	GWCODE_SINGLE_GET_FAILURE,
	GWCODE_SINGLE_SAVE_ACTION,
	GWCODE_SINGLE_SAVE_SUCCESS,
	GWCODE_SINGLE_SAVE_FAILURE,
	GWCODE_SINGLE_PUSH_ACTION,
	GWCODE_SINGLE_PUSH_FAILURE,
	GWCODE_SINGLE_PUSH_SUCCESS
} from '../actions/actionList'

const gwcode_single = (state = {data: {}, loading: false, saving: false, pushing: false}, action) => {
	switch (action.type) {
		case GWCODE_SINGLE_GET_ACTION:
			return {
				...state,
				data: {},
				loading: true,
				saving: false
			};
		case GWCODE_SINGLE_GET_SUCCESS:
			return {
				...state,
				data: action.response,
				loading: false,
				saving: false
			};
		case GWCODE_SINGLE_GET_FAILURE:
			return {
				...state,
				data: {},
				loading: false,
				saving: false
			};

		case GWCODE_SINGLE_SAVE_ACTION:
			return {
				...state,
				saving: true
			};
		case GWCODE_SINGLE_SAVE_SUCCESS:
			return {
				...state,
				saving: false
			};
		case GWCODE_SINGLE_SAVE_FAILURE:
			return {
				...state,
				saving: false
			};
		case GWCODE_SINGLE_PUSH_ACTION:
			return {
				...state,
				pushing: true
			};
		case GWCODE_SINGLE_PUSH_SUCCESS:
			return {
				...state,
				pushing: false
			};
		case GWCODE_SINGLE_PUSH_FAILURE:
			return {
				...state,
				pushing: false
			};
		default:{
			return state
		}
			
	}
}

export default gwcode_single;
