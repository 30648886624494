import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Popup, Icon} from 'semantic-ui-react'
import ReactMapboxGl, { Layer, Feature, Marker, Cluster, ZoomControl } from 'react-mapbox-gl';
const Map = ReactMapboxGl({
	accessToken: 'pk.eyJ1IjoiZnVhdHNlbmd1bCIsImEiOiJjazgzZ3lsMG4wNDZ2M2VqdTJxem1hbXd2In0.SFayuiX3qPQAp_nk_i9mjw'
});
export default class MapComponent extends Component {
	constructor(props){
		super(props);
		if(props.initialLat != 0){
			this.state={
				coords: [
					props.initialLng,
					props.initialLat
				]
			}
		} else {
			this.state = {
				coords: null
			};
		}
		
	}
	
	handleClick(e, target){
		console.log(target);
		let {lng, lat} = target.lngLat;
		this.setState({
			coords: [lng, lat]
		});
		if(this.props.onLocationUpdated)
			this.props.onLocationUpdated(lng, lat);
	};

	render() {
		return (
			<Map
				style="mapbox://styles/mapbox/streets-v9"
				containerStyle={{
					margin: 1,
					borderRadius: 4,
					height: 400,
					border: '1px solid #aaa'
				}}
				center={this.state.coords ? this.state.coords : [32.85427, 39.91987]}
				interactive={true}
				onClick={(e, target) => this.handleClick(e, target)}>
					<ZoomControl />
					{this.state.coords ? (
						<Popup content='Device Location' trigger={
							<Marker
								coordinates={this.state.coords}>
									<Icon name="point" color="blue" size="large"/>
							</Marker>} 
						/>
					) : null}
					{this.props.deviceLat ? (
						<Popup content='Device Location' trigger={
							<Marker
								coordinates={[this.props.deviceLng, this.props.deviceLat]}>
									<Icon name="point" color="red" size="large"/>
							</Marker>} 
						/>
					): null}
			</Map>	
		);
	}
}

const styles = StyleSheet.create({
	clusterMarker: {
		width: 30,
		height: 30,
		borderRadius: '50%',
		backgroundColor: '#00FF00',
		display: 'flex',
		justifyContent: 'center',
	},
	marker: {
		width: 25,
		height: 25,
		borderRadius: '50%',
		backgroundColor: '#EEEEEE',
		border: '3px solid red',
		display: 'flex',
		justifyContent: 'center',
		verticalAlign: 'center',
		textAlign: 'center',
		fontWeight: 'bold',
		color: 'red'
	}
});