import {
	DEVICE_DETAILS_ACTION,
	DEVICE_DETAILS_SUCCESS,
	DEVICE_DETAILS_FAILURE
} from './actionList';
import axios from 'axios';

import {store} from '../store';
import { connect, disconnect } from '@giantmachines/redux-websocket';
import { user_reauth_request } from './auth-actions';


export const device_details_pending = () => ({
	type: DEVICE_DETAILS_ACTION
});

export const device_details_success = (res) => ({
	type: DEVICE_DETAILS_SUCCESS,
	response: res
});

export const device_details_failure = () => ({
	type: DEVICE_DETAILS_FAILURE
});


export const device_get_details = (deviceId) => dispatch => {
	dispatch(device_details_pending());
	return new Promise((resolve, reject) => {	
		axios.get(process.env.REACT_APP_SERVER_URL + '/gateway/details/' + deviceId,{
			headers: {Authorization: 'Bearer ' + store.getState().auth.token}
		})
		.then(res => {
			if (res.status === 200) {
				dispatch(device_details_success(res.data));
				resolve(true);
			} else {
				dispatch(device_details_failure());
				reject();
			}
		})
		.catch((err) => {
			dispatch(device_details_failure());
			if (err.response && err.response.status && err.response.status === 401) {
				dispatch(user_reauth_request(store.getState().auth.token));
			}
			reject(err);
		});
	})
}


export const device_refresh_details = (deviceId) => dispatch => {
	axios.get(process.env.REACT_APP_SERVER_URL + '/gateway/details/' + deviceId,{
		headers: {Authorization: 'Bearer ' + store.getState().auth.token}
	})
	.then(res => {
		if (res.status === 200) {
			dispatch(device_details_success(res.data));
		} else {
			dispatch(device_details_failure());
		}
	})
	.catch((err) => {
		dispatch(device_details_failure());
		if (err.response && err.response.status && err.response.status === 401) {
			dispatch(user_reauth_request(store.getState().auth.token));
		}
	});
}