import React, { Component } from 'react';
import { Message, Table, Grid, Button, Icon, Menu, Segment, Header } from 'semantic-ui-react';
import Users_Create from './Users.Create';
import {users_list_request, users_delete_request} from '../actions/users-actions';
import { connect } from 'react-redux';
import Prompt from './Prompt';
import Users_Modify from './Users.Modify';
import { Translate, I18n } from "react-redux-i18n";

class Users extends Component{
	componentDidMount(){
		this.loadData();
	}
	loadData(){
		this.props.dispatch(users_list_request());
	}

	deleteUser(userId){
		this.props.dispatch(users_delete_request(userId));
	}

	render(){
		return (
			<React.Fragment>
				<Grid>
					<Grid.Row columns="equal" style={{paddingBottom: 0}}>
						<Grid.Column>
						<Header as='h3' style={{paddingTop:20, paddingLeft: 10}}>
							<Icon name="server"></Icon>
							<Header.Content><Translate value="users.header" /></Header.Content>
							{/* <Header.Subheader>A header can be attached to other content, like a segment.</Header.Subheader> */}
						</Header>
						</Grid.Column>
						<Grid.Column>
							<Menu compact icon="labeled" secondary size="tiny" floated="right">
								<Users_Create dispatch={this.props.dispatch}>
									<Menu.Item
										name='Create'
										onClick={() => {this.loadData()}}
									>
										<Icon name="user plus" />
										<Translate value="users.createUser" />
									</Menu.Item>
								</Users_Create>
								<Menu.Item
									name='Refresh'
									onClick={() => {this.loadData()}}
								>
									<Icon name="refresh" />
									<Translate value="users.refreshButton" />
								</Menu.Item>
							</Menu>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{paddingTop: 0}}>
						<Grid.Column>
							<Segment style={{padding: 0}}>
								<Table unstackable attached basic style={{margin: 0}}>
									<Table.Header>
									<Table.Row>
										<Table.HeaderCell><Translate value="users.name" /></Table.HeaderCell>
										<Table.HeaderCell><Translate value="users.email" /></Table.HeaderCell>
										<Table.HeaderCell><Translate value="users.role" /></Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
									</Table.Header>
									<Table.Body>
										{
										this.props.users.data.map(x=>{
											return (<Table.Row key={x._id}>
												<Table.Cell>{x.name}</Table.Cell>
												<Table.Cell>{x.email}</Table.Cell>
												<Table.Cell>{x.role}</Table.Cell>
												<Table.Cell>
													<Prompt
														icon="red user delete"
														action={() => this.deleteUser(x._id)} 
														title={I18n.t("users.remove_title")}
														text={I18n.t("users.remove_text", {user: x.name})}>
														<Button color="red" size="mini" loading={x.loading}><Translate value="users.deleteUser" /></Button>
													</Prompt>

													<Users_Modify
														dispatch={this.props.dispatch}
														data={x}
														userId={x._id}
													>
														<Button color="blue" size="mini"><Translate value="users.editUser" /></Button>
													</Users_Modify>
												</Table.Cell>
											</Table.Row>)
										})
										}
									</Table.Body>
								</Table>
								{
									this.props.users.loading ? (
										<Segment secondary attached='bottom'>
										<Icon name='circle notched' loading />
											<Translate value="application.loadingData" />
										</Segment>
									): null 
								}
							</Segment>
							
						</Grid.Column>
					</Grid.Row>
				</Grid>				
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	users: state.users
})
export default connect(mapStateToProps)(Users)