import React, { Component } from 'react';
import { device_get_details, device_refresh_details } from '../actions/device-details-actions';
import { connect } from 'react-redux';
import { Table, Breadcrumb, Icon, Menu, Message, Dropdown, Header, Label, Tab, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import { Translate, I18n } from "react-redux-i18n";
import DeviceDetails_AccessManagement from './DeviceDetails.AccessManagement';
import DeviceDetails_Overview from './DeviceDetails.Overview';
import DeviceDetails_History from './DeviceDetails.History';
import DeviceDetails_Programs from './DeviceDetails.Programs';
import DeviceDetails_Information_Edit from './DeviceDetails.Information.Edit';
import DeviceDetails_MeshItems from './DeviceDetails.MeshItems';
import DeviceDetails_Graphs from './DeviceDetails.Graphs';

import { device_delete_request } from '../actions/device-delete';
import {Mobile, Default} from '../components/responsive';
import { StyleSheet, css } from 'aphrodite';
import Prompt from './Prompt';

export class DeviceDetails extends Component{
	constructor(props){
		super(props);
		// this.timer = null;
	}

	
	componentDidMount(){
		this.props.dispatch(device_get_details(this.props.match.params.deviceId))
		.catch(err => {
			if(err){
				if(err.response && err.response.status && err.response.status === 403){
					this.props.history.push({
						pathname: '/devices/'
					});
				}
			}
		});

		if(this.props.history.location.hash === ""){
			this.props.history.push("#page1");
		}
		// this.timer = setInterval(() => {
		// 	this.props.dispatch(device_refresh_details(this.props.match.params.deviceId));
		// }, 5000);
	}

	deleteDeviceRequest(){
		this.props.dispatch(device_delete_request(this.props.match.params.deviceId))
		.then(() => {
			this.props.history.push({
				pathname: '/devices/'
			});
		});
	}

	tabPanes = [
		{ menuItem: I18n.t("deviceDetails.menu_overview"), render: () => <Tab.Pane><DeviceDetails_Overview deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.mesh_items"), render: () => <Tab.Pane><DeviceDetails_MeshItems deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_programs"), render: () => <Tab.Pane><DeviceDetails_Programs  deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_history"), render: () => <Tab.Pane><DeviceDetails_History deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_access"), render: () => <Tab.Pane><DeviceDetails_AccessManagement deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_graphs"), render: () => <Tab.Pane><DeviceDetails_Graphs deviceId={this.props.match.params.deviceId} /></Tab.Pane>}
	]

	tabPanesMobile = [
		{ menuItem: I18n.t("deviceDetails.menu_overview"), render: () => <Tab.Pane style={{padding: 0}}><DeviceDetails_Overview deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.mesh_items"), render: () =>    <Tab.Pane style={{padding: 0}}><DeviceDetails_MeshItems deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_programs"), render: () => <Tab.Pane style={{padding: 0}}><DeviceDetails_Programs  deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_history"), render: () =>  <Tab.Pane style={{padding: 0}}><DeviceDetails_History deviceId={this.props.match.params.deviceId} /></Tab.Pane>},
		{ menuItem: I18n.t("deviceDetails.menu_access"), render: () =>   <Tab.Pane style={{padding: 0}}><DeviceDetails_AccessManagement deviceId={this.props.match.params.deviceId} /></Tab.Pane>}
	]

	onTabChanged(evt, data){
		this.props.history.push("#page" + (data.activeIndex + 1));
	}	

	render(){
		let {data, loading} = this.props.deviceDetails;
		let activeItem = parseInt(this.props.history.location.hash.replace("#page", "")) - 1;
		if(!activeItem)
			activeItem = 0;
		
		if(loading){
			return (
				<Message attached='bottom'>
					<Icon name='circle notched' loading={true} />
					<Translate value="application.loadingData" />
				</Message>
			);
		} else if(data){
			return (
				<React.Fragment>
					<Grid stackable style={{padding: 0, margin: 0, padding: '0 !important'}}>
						<Grid.Row columns="equal" style={{padding: 0}}>
							
							<Mobile>
								<Grid.Column className={css(styles.zeroPadding)}>
									<Menu style={{borderRadius: 0, boxShadow: 'none', margin: '-1px'}}>
										<Dropdown
										fluid
										item
										simple
										style={{border: 'none'}}
										text={(data.device_name || "Unknown") + (data.mac_addr ? `(${data.mac_addr})`: null)}
										direction='right'
										>
											<Dropdown.Menu>

											<Prompt 
											action={() => this.deleteDeviceRequest()} 
											title={I18n.t("deviceDetails.deletePromptTitle")}
											text={I18n.t("deviceDetails.deletePromptMessage")}>
												<Menu.Item
													name='Delete Device'>
													<Icon name="delete" />
													<Translate value="deviceDetails.deleteDevice" />
												</Menu.Item>
											</Prompt>
											<DeviceDetails_Information_Edit
												data={data}
												>
													<Menu.Item
														name='Edit Information'
														onClick={() => {this.props.dispatch(device_refresh_details(this.props.match.params.deviceId))}}
													>
														<Icon name="edit" />
														<Translate value="deviceDetails.editInformations" />
												</Menu.Item>
											</DeviceDetails_Information_Edit>
											<Menu.Item
												name='Refresh'
												onClick={() => {this.props.dispatch(device_refresh_details(this.props.match.params.deviceId))}}
											>
												<Icon name="refresh" />
												<Translate value="deviceDetails.refresh" />
											</Menu.Item>
											</Dropdown.Menu>
											</Dropdown>
									</Menu>
								</Grid.Column>
							</Mobile>
							<Default>
								<Grid.Column>
									<Header as='h3' style={{paddingTop:10}}>
										<Icon name="dashboard"></Icon>
										<Header.Content>
											{data.device_name || "Unknown"}{data.mac_addr ? `(${data.mac_addr})`: null}
											<Label color={data.device_status == "Active" ? "green" : "red"}><Translate value={`deviceDetails_Overview.status_${data.device_status}`} /></Label>
										</Header.Content>
										{/* <Header.Subheader>A header can be attached to other content, like a segment.</Header.Subheader> */}
									</Header>
								</Grid.Column>										
								<Grid.Column>
									<Menu compact icon="labeled" secondary size="tiny" floated="right">
										<Prompt 
											action={() => this.deleteDeviceRequest()} 
											title={I18n.t("deviceDetails.deletePromptTitle")}
											text={I18n.t("deviceDetails.deletePromptMessage")}>
											<Menu.Item
												name='Delete Device'
											>
												<Icon name="delete" color="red" />
												<Translate value="deviceDetails.deleteDevice" />
											</Menu.Item>
										</Prompt>
										<DeviceDetails_Information_Edit
											data={data}
											>
												<Menu.Item
													name='Edit Information'
													onClick={() => {this.props.dispatch(device_refresh_details(this.props.match.params.deviceId))}}
												>
													<Icon name="edit" />
													<Translate value="deviceDetails.editInformations" />
											</Menu.Item>
										</DeviceDetails_Information_Edit>
										<Menu.Item
											name='Refresh'
											onClick={() => {this.props.dispatch(device_refresh_details(this.props.match.params.deviceId))}}
										>
											<Icon name="refresh" />
											<Translate value="deviceDetails.refresh" />
										</Menu.Item>
									</Menu>
								</Grid.Column>
							</Default>
						</Grid.Row>
						<Grid.Row style={{paddingTop: 0}}>
							
							<Mobile>
								<Grid.Column style={{padding: '0 !important'}}>
									<Tab menu={{stackable: true, attached: true}} activeIndex={activeItem} onTabChange={(e, data) => this.onTabChanged(e, data)} panes={this.tabPanesMobile} renderActiveOnly={true} />
								</Grid.Column>
							</Mobile>
							<Default>
								<Grid.Column>
									<Tab activeIndex={activeItem} onTabChange={(e, data) => this.onTabChanged(e, data)} panes={this.tabPanes} renderActiveOnly={true} />
								</Grid.Column>
							</Default>
							
						</Grid.Row>
					</Grid>
				</React.Fragment>
			);
		}

		
	}
}

const styles = StyleSheet.create({
	zeroPadding: {
		padding: 0
	}
});


const mapStateToProps = state => ({
	deviceDetails: state.deviceDetails
})
export default connect(mapStateToProps)(DeviceDetails)